import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoRootModule } from '@shared/transloco/transloco-root.module';
import { ClientsRountingModule } from './clients-rounting.module';
import { ClientsComponent } from './component/clients/clients.component';
import { SharedModule } from 'primeng/api';
import { ClientCreateComponent } from './component/client-create/client-create.component';
import { ClientSearchComponent } from './component/client-search/client-search.component';
import { ClientSelectComponent } from './component/client-select/client-select.component';

@NgModule({
  declarations: [ClientsComponent, ClientCreateComponent, ClientSearchComponent, ClientSelectComponent],
  imports: [
    CommonModule,
    TranslocoRootModule,
    ClientsRountingModule,
    SharedModule
  ],
  exports: [ClientsComponent]
})
export class ClientsModule { }
