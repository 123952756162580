import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";

export class GetFileResponse {
    constructor(
        public errormessage: string,
        public executionStatus: number,
        public file: string
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class GetFileResponseAdapter implements Adapter<GetFileResponse> {
  adapt(item: any): GetFileResponse {
    return item ? new GetFileResponse(
      item.ErrorMessage,
      item.Status,
      item.File
      ) : null;
  }
}