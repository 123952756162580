import { Component, OnInit, Input } from '@angular/core';
import { DocumentElem } from '@features/documents/model/document-elem';

@Component({
  selector: 'app-document-elem',
  templateUrl: './document-elem.component.html',
  styleUrls: ['./document-elem.component.scss']
})
export class DocumentElemComponent implements OnInit {
  @Input() model: DocumentElem
  constructor() { }

  ngOnInit(): void {
  }

}
