import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

export const sidebarCssClasses: string[] = [
  'sidebar-show',
  'sidebar-sm-show',
  'sidebar-md-show',
  'sidebar-lg-show',
  'sidebar-xl-show',
];

@Component({
  selector: 'app-sidebar',
  template: `<ng-content></ng-content>`,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() public compact: boolean;
  @Input() public display: string = null;
  @Input() public fixed: boolean;
  @Input() public offCanvas: boolean;

  private _minimized: boolean;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    private readonly hostElement: ElementRef,
  ) {
    renderer.addClass(hostElement.nativeElement, 'sidebar');
  }

  @Input()
  public get minimized(): boolean {
    return this._minimized;
  }

  public set minimized(value: boolean) {
    this._minimized = value;
    this.isMinimized();
  }

  public ngOnInit(): void {
    this.displayBreakpoint(this.display);
    this.isCompact(this.compact);
    this.isFixed(this.fixed);
    this.isMinimized(this.minimized);
    this.isOffCanvas(this.offCanvas);
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'sidebar-fixed');
  }

  public isCompact(compact: boolean = this.compact): void {
    if (compact) {
      this.renderer.addClass(this.document.body, 'sidebar-compact');
    }
  }

  public isFixed(fixed: boolean = this.fixed): void {
    if (fixed) {
      this.renderer.addClass(this.document.body, 'sidebar-fixed');
    }
  }

  public isMinimized(minimized: boolean = this.minimized): void {
    if (minimized) {
      this.renderer.addClass(this.document.body, 'sidebar-minimized');
    } else {
      this.renderer.removeClass(this.document.body, 'sidebar-minimized');
    }
  }

  public isOffCanvas(offCanvas: boolean = this.offCanvas): void {
    if (offCanvas) {
      this.renderer.addClass(this.document.body, 'sidebar-off-canvas');
    }
  }

  public displayBreakpoint(display: string = this.display): void {
    if (display !== null) {
      const cssClass = display ? `sidebar-${display}-show` : sidebarCssClasses[0];
      this.renderer.addClass(this.document.body, cssClass);
    }
  }
}
