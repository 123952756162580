import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroupComponent } from './form/form-group.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SidebarModule } from '@shared/sidebar';
import { PrimengModule } from './primeng/primeng.module';
import { LoaderModule } from './loader';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { ImageModule } from 'primeng/image';

@NgModule({
  declarations: [FormGroupComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SidebarModule,
    PrimengModule,
    LoaderModule,
    ButtonModule,
    RippleModule,
    ImageModule
  ],
  exports: [FormGroupComponent, ReactiveFormsModule, PrimengModule, LoaderModule, ButtonModule, RippleModule, ImageModule]
})
export class SharedModule { }
