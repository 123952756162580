import { Component, OnInit, Input, Output } from '@angular/core';
import { GiftCardActivationModel } from '@features/gift-card-activation/model/gift-card-activation.model';
import { EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GiftCardActivationService } from '@features/gift-card-activation/gift-card-activation.service';
import { NotificationService } from '@shared/notification/notification.service';
import { TranslocoService } from '@ngneat/transloco';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';

@Component({
  selector: 'app-gift-card-activation-elem',
  templateUrl: './gift-card-activation-elem.component.html',
  styleUrls: ['./gift-card-activation-elem.component.scss']
})

export class GiftCardActivationElemComponent implements OnInit {
  public form: FormGroup;
  @Output() newCardEvent = new EventEmitter();
  @Output() editCardEvent = new EventEmitter<GiftCardActivationModel>();
  @Input() model: GiftCardActivationModel;
  @Input() isLast: boolean;
  public showAddButton = false;
  private authService: AuthRestService;

  constructor(
    private readonly fb : FormBuilder,
    private giftCardActivationService: GiftCardActivationService,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
    private authRestService: AuthRestService
  ) { 
    this.authService = authRestService;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      number: [null, Validators.required], 
      value: [null]
    });
  }

  public numberChanged() {
    if (!this.model.confirmed) {
      this.model.number = this.form.get('number').value;
    }

    if (!this.model.number || this.model.number == '') {
      this.model.canActivate = false;
      return;
    }

    this.editCardEvent.emit(this.model);
  }
  
  public valueChanged() {
    if (!this.model.confirmed) {
      this.model.number = this.form.get('number').value;
    }

    if (this.form.get('value').value < 0) {
      this.form.get('value').patchValue(0);
    }
    this.model.value = this.form.get('value').value;
    this.editCardEvent.emit(this.model);
  }

  public newCard($event) {
    this.model.confirmed = true;
    this.newCardEvent.emit();
  }

  public checkNumber($event) {
    const number = this.form.get('number').value;
    const value = this.form.get('value').value;
    
    if (number) {
      this.giftCardActivationService.getCard(number, this.authService.userValue.userID).subscribe((res) => {

        if (!res) {
          this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.card-not-exists'));
          this.model.canActivate = false;
          return;
        }

        if (res.executionStatus == 1) {
          this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.card-already-activated'));
          this.model.canActivate = false;
          return;
        }

        if (res.executionStatus == 2) {
          this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.cannot-activate-card'));
          this.model.canActivate = false;
          return;
        }

        if (res.cards === undefined) {
          this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.card-not-exists'));
          this.model.canActivate = false;
          return;
        }
        
        if (res.cards[0].cardValue) {
          this.form.get('value').patchValue(res.cards[0].cardValue, {emitEvent: true});
          this.form.get('value').disable();
        } else {
          this.form.get('value').enable();
        }

        this.model.canActivate = true;
        this.showAddButton = true;

        this.editCardEvent.emit(this.model);
      });
    }
    else {
      this.form.get('value').enable();
      this.form.reset();
    }
  }
}