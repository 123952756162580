import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentListComponent } from './component/document-list/document-list.component';
import { SharedModule } from '@shared/shared.module';
import { DocumentsAuthGuard } from '@core/guards/documents-auth.guard';

const routes: Routes = [
  { 
    path: 'documents', data: { id: 'documents' }, component: DocumentListComponent,
    canLoad: [DocumentsAuthGuard],
    canActivate: [DocumentsAuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  exports: [RouterModule, SharedModule]
})
export class DocumentsRoutingModule { }
