import { Component, Input, OnInit } from '@angular/core';
import { NavItemModel } from '@core/models/nav-item.model';

@Component({
  selector: 'app-sidebar-nav-divider',
  template: ``,
})
export class SidebarNavDividerComponent implements OnInit {
  @Input() public item: NavItemModel;

  constructor() {}

  public ngOnInit(): void {}
}
