import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentUser } from '@features/auth/model/current-user';
import { CookieService } from 'ngx-cookie-service';
import { CookieKeyEnum } from '@shared/enums/cookie-key.enum';
import { GiftCardActivationRestService } from '@api/rest/gift-card-activation/gift-card-activation-rest.service';
import { AuthToken } from '@features/auth/model/auth-token';

@Injectable({ providedIn: 'root' })
export class AuthRestService {
  private userSubject: BehaviorSubject<CurrentUser>;
  public user: Observable<CurrentUser>;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private giftCardActivationRestService: GiftCardActivationRestService
  ) {
    this.initialize();
  }

  private initialize(): void {
    const tokenCookieValue: string = this.cookieService.get(
      CookieKeyEnum.token
    );
    const cookieParam = tokenCookieValue == '' ? null : tokenCookieValue;
    this.userSubject = new BehaviorSubject<CurrentUser>(
      this.getUserFromToken(cookieParam)
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): CurrentUser {
    return this.userSubject.value;
  }

  public get token(): string {
    const tokenCookieValue: string = this.cookieService.get(
      CookieKeyEnum.token
    );
    const cookieParam = tokenCookieValue == '' ? null : tokenCookieValue;

    if (!cookieParam) {
      return null;
    }

    return JSON.parse(cookieParam)?.access_token;
  }

  public login(login: string, password: string): Observable<AuthToken> {
    return this.giftCardActivationRestService.auth(login, password).pipe(
      map((token: AuthToken) => {
        var date = new Date(parseInt(token.expires_in + '999'));
        if (token.access_token) {
          this.cookieService.set(CookieKeyEnum.token, JSON.stringify(token), {
            expires: date,
          });
          this.userSubject.next(this.getUserFromToken(token.access_token));
        }
        return token;
      })
    );
  }

  public logout() {
    // remove user from cookie storage and set current user to null
    this.cookieService.delete(CookieKeyEnum.token);
    this.userSubject.next(null);
    this.router.navigate(['/auth/login']);
  }

  getUserFromToken(token: string): CurrentUser {
    if (!token) {
      return null;
    }

    const user = JSON.parse(atob(token.split('.')[1]));

    return new CurrentUser(
      user.ClientID,
      user.Login,
      user.AccessToGiftCardActivation,
      user.AccessToCardActivationHistory,
      user.AccessToDocuments,
      user.AccessToClients
    );
  }
}
