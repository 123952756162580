import {
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    SimpleChanges,
  } from '@angular/core';
  import { AbstractControl, NgControl } from '@angular/forms';
  import { Subject } from 'rxjs';
  
  let nextUniqueId = 0;
  
  @Directive({
    selector: 'input[appInput], textarea[appInput], p-inputNumber[appInput]',
    exportAs: 'appInput',
  })
  export class InputDirective implements OnInit, OnChanges, OnDestroy {
    public readonly stateChanges: Subject<void> = new Subject<void>();
    public focused = false;
  
    @Input() public labelTrans: string = null;
    @Input() public placeholder: string;
    protected _inputValueAccessor: { value: string };
    protected _uid = `app-input-${nextUniqueId++}`;
    protected _disabled = false;
    protected _id: string;
    protected _type = 'text';
  
    private _readonly = false;
  
    constructor(
      protected _elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
      @Optional() @Self() public ngControl: NgControl,
    ) {
      this._inputValueAccessor = this._elementRef.nativeElement;
    }
  
    @HostBinding('class.p-inputtext') public get isFormControl(): boolean {
      return true;
    }
  
    @HostBinding('attr.required') public get required(): boolean {
      return this.isRequired;
    }
  
    @HostBinding('class.is-required') public get classRequired(): boolean {
      return this.isRequired;
    }
  
    @HostBinding('attr.readonly') public get attributeReadonly(): string {
      return this.readonly ? 'readonly' : null;
    }
  
    public get isRequired(): boolean {
      const control = this.ngControl.control;
      if (control.validator) {
        const validator = control.validator({} as AbstractControl);
        if (validator && validator.required) {
          return true;
        }
      }
      return false;
    }
  
    public get empty(): boolean {
      return !this.ngControl.value;
    }
  
    @Input()
    public get disabled(): boolean {
      if (this.ngControl && this.ngControl.disabled !== null) {
        return this.ngControl.disabled;
      }
      return this._disabled;
    }
  
    public set disabled(value: boolean) {
      this._disabled = value;
  
      if (this.focused) {
        this.focused = false;
        this.stateChanges.next();
      }
    }
  
    @Input()
    public get id(): string {
      return this._id;
    }
  
    public set id(value: string) {
      this._id = value || this._uid;
    }
  
    @Input()
    public get type(): string {
      return this._type;
    }
  
    public set type(value: string) {
      this._type = value;
    }
  
    @Input()
    public get value(): string {
      return this._inputValueAccessor.value;
    }
  
    public set value(value: string) {
      value = value === '' ? null : value;
      if (value !== this.value) {
        this._inputValueAccessor.value = value;
        this.stateChanges.next();
      }
    }
  
    @Input()
    public get readonly(): boolean {
      return this._readonly;
    }
  
    public set readonly(value: boolean) {
      this._readonly = value;
    }
  
    @HostListener('focus', ['$event.target'])
    public onFocus(e: FocusEvent): void {
      this._focusChanged(true);
    }
  
    @HostListener('blur', ['$event.target'])
    public onBlur(e: Event): void {
      this._focusChanged(false);
    }
  
    public ngOnInit(): void {
      this.stateChanges.next();
    }
  
    public ngOnChanges(changes: SimpleChanges): void {
      this.stateChanges.next();
    }
  
    public ngOnDestroy(): void {
      this.stateChanges.complete();
    }
  
    public _focusChanged(isFocused: boolean): void {
      if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
        this.focused = isFocused;
        this.stateChanges.next();
      }
    }
  
    public clear(): void {
      this._inputValueAccessor.value = null;
      this.stateChanges.next();
    }
  }
  