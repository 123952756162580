import { Injectable } from '@angular/core';
import { SimpleResponse } from '@api/model/response';
import { GiftCardActivationRestService } from '@api/rest/gift-card-activation/gift-card-activation-rest.service';
import { GetActivationHistoryResponse } from '@features/card-activation-history/model/get-activation-history-response';
import { ClientModel } from '@features/clients/client-model';
import { CreateClientResponse } from '@features/clients/create-client.response';
import { GetClientResponse } from '@features/clients/get-client-response';
import { GetDocumentsResponse } from '@features/documents/model/get-documents-response';
import { Observable, Subject } from 'rxjs';
import { CardListActivatedResponse } from './model/card-list-activated-response';
import { GiftCardActivatedModel } from './model/gift-card-activated-model';
import { GiftCardActivationTableElemModel } from './model/gift-card-activation-table-elem-model';
import { GiftCardActivationModel } from './model/gift-card-activation.model';
import { GiftCardRangeResponse } from './model/gift-card-range-response';
import { GiftCardResponse } from './model/gift-card-response';

@Injectable({
  providedIn: 'root'
})
export class GiftCardActivationService {
  private completed: Subject<string[]> = new Subject<string[]>();
  public completed$: Observable<string[]>;

  constructor(private giftCardActivationRestService: GiftCardActivationRestService) { 
    this.completed$ = this.completed.asObservable();
  }

  public activateCard(cardNumber: string, cardOperationID: string, value: number): Observable<GiftCardActivatedModel> {
    return this.giftCardActivationRestService.activateCard(cardNumber, cardOperationID, value);
  }

  public activateCardList(cardList: GiftCardActivationModel[], userID: string): Observable<CardListActivatedResponse> {
    return this.giftCardActivationRestService.activateCardList(cardList, userID);
  }

  public getCard(cardNumber: string, userID: string, batchID: string = ""): Observable<GiftCardResponse> {
    return this.giftCardActivationRestService.getCard(cardNumber, userID, batchID);
  }

  public getTransactions(userID: string): Observable<GiftCardActivationTableElemModel[]> {
    return this.giftCardActivationRestService.getTransactions(userID);
  }

  public setCardActivated(cardNumbers: string[]) {
    this.completed.next(cardNumbers);
  }

  public getCardRange(cardNumberFrom: string, cardNumberTo: string, userID: string): Observable<GiftCardRangeResponse> {
    return this.giftCardActivationRestService.getCardRange(cardNumberFrom, cardNumberTo, userID);
  }

  public getDocuments(userID: string): Observable<GetDocumentsResponse> {
    return this.giftCardActivationRestService.getFtpFiles(userID);
  }

  public getFile(userID: string, filename: string): Observable<any> {
    return this.giftCardActivationRestService.getFile(userID, filename);
  }

  public getActivationHistory(userID: string): Observable<GetActivationHistoryResponse> {
    return this.giftCardActivationRestService.getActivationHistory(userID);
  }

  public createInvoice(clientId: string, cardActivationHistoryIDList: string[]): Observable<SimpleResponse> {
    return this.giftCardActivationRestService.createInvoice(clientId, cardActivationHistoryIDList);
  }

  public getInvoice(invoiceID: string): Observable<any> {
    return this.giftCardActivationRestService.getInvoice(invoiceID);
  }

  public getClient(name: string, taxId: string): Observable<GetClientResponse> {
    return this.giftCardActivationRestService.getClient(name, taxId);
  }

  public createClient(client: ClientModel): Observable<CreateClientResponse> {
    return this.giftCardActivationRestService.createClient(client);
  }
}