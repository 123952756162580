import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
// import { TranslocoService } from '@ngneat/transloco';
// import { AppCalendarComponent } from '@shared/calendar/calendar.component';
import { InputDirective } from '@shared/input/input.directive';
import { AutoComplete } from 'primeng/autocomplete';
import { Checkbox } from 'primeng/checkbox';
import { Dropdown } from 'primeng/dropdown';
import { InputMask } from 'primeng/inputmask';
import { InputNumber } from 'primeng/inputnumber';
import { InputSwitch } from 'primeng/inputswitch';
import { MultiSelect } from 'primeng/multiselect';
import { RadioButton } from 'primeng/radiobutton';
import { SelectButton } from 'primeng/selectbutton';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

type controlType =
  | InputDirective
  | InputMask
  | Dropdown
  | MultiSelect
  | InputSwitch
  | AutoComplete
  | SelectButton
  // | AppCalendarComponent
  | InputNumber
  | Checkbox
  | RadioButton;

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormGroupComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  @Input() public icon: string = null;
  @Input() public hint: string = null;
  @Input() public labelText: string = null;
  @Input() public hasPlaceholder = false;
  public hintText: string = null;

  @ContentChild(Dropdown) public _controlPrimeNgDropdown: Dropdown;
  // @ContentChild(AppCalendarComponent) public _controlPrimeNgCalendar: AppCalendarComponent;
  @ContentChild(InputSwitch) public _controlPrimeNgInputSwitch: InputSwitch;
  @ContentChild(InputMask) public _controlPrimeNgInputMask: InputMask;
  @ContentChild(MultiSelect) public _controlPrimeNgMultiselect: MultiSelect;
  @ContentChild(AutoComplete) public _controlPrimeNgAutocomplete: AutoComplete;
  @ContentChild(SelectButton) public _controlPrimeNgSelectButton: SelectButton;
  @ContentChild(RadioButton) public _controlPrimeNgRadioButton: RadioButton;
  @ContentChild(InputNumber) public _controlPrimeNgNumber: InputNumber;
  @ContentChild(Checkbox) public _controlPrimeNgCheckbox: Checkbox;
  @ContentChild(InputDirective) public _controlNonStatic: InputDirective;
  @ContentChild(InputDirective, { static: true }) public _controlStatic: InputDirective;

  private readonly _destroyed = new Subject<void>();

  private _label: string = null;

  private readonly _explicitFormFieldControl: controlType;
  private _required: boolean;

  private _readonly: boolean;

  constructor(
    public _elementRef: ElementRef,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslocoService,
  ) {}

  private static isInputControl(obj: controlType): obj is InputDirective {
    return obj instanceof InputDirective;
  }

  private static isCheckboxControl(obj: controlType): obj is Checkbox {
    return obj instanceof Checkbox;
  }

  private static isInputMaskControl(obj: controlType): obj is InputMask {
    return obj instanceof InputMask;
  }

  private static isAutocompleteControl(obj: controlType): obj is AutoComplete {
    return obj instanceof AutoComplete;
  }

  // private static isCalendarControl(obj: controlType): obj is AppCalendarComponent {
  //   return obj instanceof AppCalendarComponent;
  // }

  private static isRadioButtonControl(obj: controlType): obj is RadioButton {
    return obj instanceof RadioButton;
  }

  private static isInputNumberControl(obj: controlType): obj is InputNumber {
    return obj instanceof InputNumber;
  }

  @Input()
  public set label(label: string) {
    this._label = label;
  }

  public get label(): string {
    return this._label;
  }

  public get required(): boolean {
    return this._required;
  }

  @Input()
  public set required(value: boolean) {
    this._required = value;
  }

  public get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  public set readonly(value: boolean) {
    this._readonly = value;
  }

  public get _control(): controlType {
    return (
      this._explicitFormFieldControl ||
      this._controlNonStatic ||
      this._controlStatic ||
      this._controlPrimeNgDropdown ||
      this._controlPrimeNgInputSwitch ||
      this._controlPrimeNgInputMask ||
      this._controlPrimeNgMultiselect ||
      this._controlPrimeNgAutocomplete ||
      this._controlPrimeNgSelectButton ||
      // this._controlPrimeNgCalendar ||
      this._controlPrimeNgRadioButton ||
      this._controlPrimeNgNumber ||
      this._controlPrimeNgCheckbox
    );
  }

  @HostBinding('class.is-focused')
  public get focused(): boolean {
    if (this.hasPlaceholder) {
      return true;
    } else if (FormGroupComponent.isInputControl(this._control)) {
      return this._control.focused;
    } else if (FormGroupComponent.isAutocompleteControl(this._control)) {
      return this._control.focus;
    } 
    // else if (FormGroupComponent.isCalendarControl(this._control)) {
    //   return this._control.calendar?.focus;
    // } 
    else if (FormGroupComponent.isInputNumberControl(this._control)) {
      return this._control?.focused;
    } else if (FormGroupComponent.isInputMaskControl(this._control)) {
      return this._control.focused;
    } else if (FormGroupComponent.isCheckboxControl(this._control)) {
      return this._control.focused;
    }
    return true;
  }

  @HostBinding('class.is-filled')
  public get filled(): boolean {
    if (FormGroupComponent.isInputControl(this._control)) {
      return !this._control.empty;
    } else if (FormGroupComponent.isAutocompleteControl(this._control)) {
      return this._control.filled !== undefined && this._control.value !== null;
    }
    //  else if (FormGroupComponent.isCalendarControl(this._control)) {
    //   return this._control.calendar?.filled;
    // } 
    else if (FormGroupComponent.isInputNumberControl(this._control)) {
      return this._control?.filled;
    } else if (FormGroupComponent.isInputMaskControl(this._control)) {
      return this._control.filled;
    } else if (FormGroupComponent.isCheckboxControl(this._control)) {
      return this._control.checked();
    }
    return true;
  }

  @HostBinding('class.is-required')
  public get classRequired(): boolean {
    return this.required;
  }

  @HostBinding('class.is-readonly')
  public get isReadonly(): boolean {
    return this._readonly;
  }

  public get isRadioButton(): boolean {
    return FormGroupComponent.isRadioButtonControl(this._control);
  }

  public ngOnInit(): void {
    // if (this.hint !== null) {
    //   //this.translateService.selectTranslate(this.hint).subscribe((el: string) => (this.hintText = el));
    // }

    // if (this.label !== null) {
    //   //this.translateService.selectTranslate(this.label).subscribe((el: string) => (this.labelText = el));
    // }
  }

  public ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }

  public ngAfterContentInit(): void {
    const control = this._control;

    if (FormGroupComponent.isInputControl(control)) {
      control.stateChanges.subscribe(() => {
        this._changeDetectorRef.markForCheck();
      });
    }

    if (FormGroupComponent.isInputControl(control) && control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges.pipe(takeUntil(this._destroyed)).subscribe((value: string) => {
        if (value === '') {
          control.clear();
        }
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  public ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
