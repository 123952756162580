import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoRootModule } from '@shared/transloco/transloco-root.module';
import { CardActivationHistoryRountingModule } from './card-activation-history-rounting.module';
import { CardActivationHistoryTableComponent } from './component/card-activation-history-table/card-activation-history-table.component';
import { SharedModule } from 'primeng/api';

@NgModule({
  declarations: [CardActivationHistoryTableComponent],
  imports: [
    CommonModule,
    TranslocoRootModule,
    CardActivationHistoryRountingModule,
    SharedModule
  ],
  exports: [CardActivationHistoryTableComponent]
})
export class CardActivationHistoryModule { }
