
//string helpers
export const nameof = <T>(name: Extract<keyof T, string>): string => name;

//array helpers

Array.prototype.distinct = function<T>() {
    return distinct<T>(this);
}
Array.prototype.distinctBy = function<T>(fieldName: string) {
    return distinctBy<T>(this, fieldName);
}

declare global {
    interface Array<T> {
        distinct<T>(): Array<T>;
        distinctBy<T>(fieldName: string): Array<T>;
    }
}

export const distinctBy = <T>(array, fieldName): T => array.filter(
    (item, i, arr) => arr.findIndex(t => t[fieldName] === item[fieldName]) === i
);
export const distinct = <T>(array): T => array.filter(
    (item, i, arr) => arr.findIndex(t => t === item) === i 
);
