<div class="row new-card">
<ng-container [formGroup]="form">
        <div class="col-lg-8 col-sm-12">
                <app-form-group>
                        <input 
                        formControlName="number" 
                        appInput type="text" 
                        maxlength="256" 
                        placeholder="{{ 'features.gift-card-activation.enter-card-number' | transloco }}" 
                        (focusout)="checkNumber($event)"
                        (ngModelChange)="numberChanged()"
                        [class.redBorder] = "model.canActivate == false"/>
                </app-form-group>
        </div>
        <div class="col-lg-3 col-sm-12">
                <app-form-group>
                        <input formControlName="value" appInput type="text" mask="separator.2" thousandSeparator="" separatorLimit="999999" min="0" maxlength="256" placeholder="{{ 'common.enter-value' | transloco }}" (ngModelChange)="valueChanged()"/>
                </app-form-group>
        </div>
        <div class="col-lg-1 col-sm-12">
                <ng-template [ngIf]="isLast && showAddButton">
                        <app-form-group>
                                <button pButton (click)="newCard($event)" [disabled]="form.invalid" icon="pi pi-plus" class="p-button-rounded p-button-secondary new-card-btn"></button>
                        </app-form-group>
                </ng-template>
        </div>
</ng-container>
</div>