import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";
import { GetActivationHistoryModel, GetActivationHistoryModelAdapter } from "./get-activation-history-model";

export class GetActivationHistoryResponse {
    constructor(
        public executionStatus: number,
        public executionMessage: string = '',
        public activationHistoryList: GetActivationHistoryModel[]
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class GetActivationHistoryResponseAdapter implements Adapter<GetActivationHistoryResponse> {
  adapt(item: any): GetActivationHistoryResponse {
    return item ? new GetActivationHistoryResponse(
      item.data.ExecutionStatus,
      item.data.ExecutionMessage,
      item.data.CardActivationHistoryList.map(i => new GetActivationHistoryModelAdapter().adapt(i))
      ) : null;
  }
}