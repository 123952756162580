<ng-container [ngSwitch]="linkType">
  <a *ngSwitchCase="'external'" [ngClass]="getLinkClass()" href="{{ href }}">
    <i *ngIf="helper.hasIcon(item)" [ngClass]="helper.getIconClass(item)"></i>
    <span>{{ 'core.navigation.' + item.id | transloco }}</span>
    <span *ngIf="helper.hasBadge(item)" [ngClass]="helper.getBadgeClass(item)">{{ item.badge.text }}</span>
  </a>
  <a
    *ngSwitchDefault
    [ngClass]="getLinkClass()"
    [attr.disabled]="isDisabled()"
    routerLinkActive="active"
    [routerLink]="[href]"
    pTooltip="{{ 'core.navigation.' + item.id | transloco }}">
    <i *ngIf="helper.hasIcon(item)" [ngClass]="helper.getIconClass(item)" routerLinkActive="active"></i>
    <span>{{ 'core.navigation.' + item.id | transloco }}</span>
    <span *ngIf="helper.hasBadge(item)" [ngClass]="helper.getBadgeClass(item)">{{ item.badge.text }}</span>
  </a>
</ng-container>
