import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidebarNavDividerComponent } from '@shared/sidebar/component/sidebar-nav/component/divider/divider.component';
import { SidebarNavDropdownComponent } from '@shared/sidebar/component/sidebar-nav/component/dropdown/dropdown.component';
import { SidebarFooterComponent } from '@shared/sidebar/component/sidebar-nav/component/footer/footer.component';
import { SidebarNavItemsComponent } from '@shared/sidebar/component/sidebar-nav/component/items/items.component';
import { SidebarNavLabelComponent } from '@shared/sidebar/component/sidebar-nav/component/label/label.component';
import { SidebarNavLinkComponent } from '@shared/sidebar/component/sidebar-nav/component/link/link.component';
import { SidebarNavTitleComponent } from '@shared/sidebar/component/sidebar-nav/component/title/title.component';
import {
  NavDropdownDirective,
  NavDropdownToggleDirective,
} from '@shared/sidebar/component/sidebar-nav/directive/sidebar-nav.directive';
import { SidebarNavHelper } from '@shared/sidebar/component/sidebar-nav/service/sidebar-nav.helper';
import { SidebarNavComponent } from '@shared/sidebar/component/sidebar-nav/sidebar-nav.component';
import { SidebarComponent } from '@shared/sidebar/component/sidebar/sidebar.component';
import { TranslocoRootModule } from '@shared/transloco/transloco-root.module';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [CommonModule, RouterModule, TooltipModule, TranslocoRootModule],
  declarations: [
    SidebarComponent,
    SidebarNavComponent,
    SidebarNavItemsComponent,
    SidebarNavDropdownComponent,
    SidebarNavDividerComponent,
    SidebarNavLabelComponent,
    SidebarNavLinkComponent,
    SidebarNavTitleComponent,
    NavDropdownDirective,
    NavDropdownToggleDirective,
    SidebarFooterComponent,
  ],
  exports: [
    SidebarComponent,
    SidebarNavComponent,
    SidebarNavItemsComponent,
    SidebarNavDropdownComponent,
    SidebarNavDividerComponent,
    SidebarNavLabelComponent,
    SidebarNavLinkComponent,
    SidebarNavTitleComponent,
    NavDropdownDirective,
    NavDropdownToggleDirective,
    SidebarFooterComponent,
  ],
  providers: [SidebarNavHelper],
})
export class SidebarModule {}
