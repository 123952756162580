import { Component, OnInit, Output} from '@angular/core';
import { GiftCardActivationModel } from '../../model/gift-card-activation.model';
import { EventEmitter } from '@angular/core';
import { GiftCardActivationService } from '@features/gift-card-activation/gift-card-activation.service';
import { NotificationService } from '@shared/notification/notification.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-gift-card-activation-single',
  templateUrl: './gift-card-activation-single.component.html',
  styleUrls: ['./gift-card-activation-single.component.scss']
})
export class GiftCardActivationSingleComponent implements OnInit {
  @Output() activatePopupEvent: EventEmitter<any> = new EventEmitter();
  public cards: GiftCardActivationModel[] = [];
  public loading: boolean = false;
  public submitted: boolean = false;
  public eventData: string;
  
  constructor(private giftCardActivationService: GiftCardActivationService,
    private notificationService: NotificationService,
    private translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.pushNewCard();
    this.giftCardActivationService.completed$.subscribe((cardNumbers) => {
      if (cardNumbers) {
        this.cards = this.cards.filter(o => !cardNumbers.includes(o.number));
      }
    })
  }
  public submit(): void { }

  public emitActivatePopupEvent($event: Event): void {
    if (this.anyCardValid())
    {
      if (this.cards.length === this.getValidCards().length) {
        this.cards.forEach((c) => {
          c.confirmed = true;
        })
      }

      let sum: number = this.getValidCards().map(card => Math.round((Number(card.value) + Number.EPSILON) * 100) / 100).reduce((total, num) => total + num);

      if (isNaN(sum)) {
        sum = 0;
      }
      
      let valuesSum: string = sum.toFixed(2);

      this.activatePopupEvent.emit({
          event: $event,
          cardsAmount: this.getValidCards().length,
          valuesSum: valuesSum
        }
      );
    }
  }

  public pushNewCard(): void {
    this.cards.push(new GiftCardActivationModel(''));
  }

  public editCard($event: GiftCardActivationModel): void {
    let cardsFiltered: GiftCardActivationModel[] = this.cards.filter(c => c.number === $event.number);

    if (cardsFiltered != undefined && cardsFiltered.length > 1) {
      cardsFiltered[cardsFiltered.length - 1].canActivate= false;
      this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.card-already-exists-onlist'));
      return;
    }

    if (cardsFiltered[0]) {
      cardsFiltered[0].value = $event.value;
    }
    else {
      cardsFiltered[0].value = null;
    }
  }

  private anyCardValid(): boolean {
    return this.getValidCards().length > 0;
  }

  public getValidCards(): GiftCardActivationModel[] {
    return this.cards.filter(c => c.canActivate);
  }
}