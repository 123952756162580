
<div class="logo-container mb-3"></div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col">
      <app-form-group>
          <input formControlName="login" appInput type="text" maxlength="256" placeholder="Login" [class.is-invalid]="hasErrorInField('login')" />
          <div *ngIf="submitted && f.login.errors" class="invalid-feedback" style="display: block">
              <div *ngIf="f.login.errors.required">Field required</div>
          </div>
      </app-form-group>
    </div>
  </div>
  <div class="row">
    <div class="col">
          <app-form-group>
              <input formControlName="password" appInput type="password" maxlength="256" placeholder="Password" [class.is-invalid]="hasErrorInField('password')"/>
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback" style="display: block">
                  <div *ngIf="f.password.errors.required">Field required</div>
              </div>
          </app-form-group>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-form-group>
        <button pButton type="submit" label="{{ 'features.auth.login.form.fields.submit' | transloco }}">
        </button>
      </app-form-group>
    </div>
  </div>
</form>
<app-loader *ngIf="loading"></app-loader>
