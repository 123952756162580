import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";

export class CreateClientResponse {
    constructor(
        public executionStatus: number,
        public executionMessage: string = ''
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class CreateClientResponseAdapter implements Adapter<CreateClientResponse> {
  adapt(item: any): CreateClientResponse {
    return item ? new CreateClientResponse(
      item.ExecutionStatus,
      item.ExecutionMessage
      ) : null;
  }
}