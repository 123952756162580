<form [formGroup]="clientSearchForm">
    <div class="container">
        <div class="row d-flex">
            <span class="mr-3">
                <input pInputText type="text" id="name" formControlName="name" placeholder="{{ 'features.clients.fields.name' | transloco }}">
            </span>
            <span class="mr-3">
                <input pInputText type="text" id="taxId" formControlName="taxId" placeholder="{{ 'features.clients.fields.taxId' | transloco }}">
            </span>
            <div class="text-center">
                <p-button label="{{ 'features.clients.search' | transloco }}" type="submit" (onClick)="confirm()" [disabled]="!clientSearchForm.valid && !loading" >
                    <i class="pi pi-spin pi-spinner spinner" *ngIf="loading"></i>
                </p-button>
            </div>
        </div>
    </div>
</form>