import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '@features/auth/model/current-user';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { ApplicationConfig } from '@core/config/application.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'b2b-cinema-portal';
  user: CurrentUser;

  constructor(
    private authRestService: AuthRestService,
    private readonly localeState: ApplicationConfig
  ) {
    this.subscribeUser();
  }
  public ngOnInit(): void {
    this.localeState.init();
  }

  private subscribeUser(): void {
    this.authRestService.user.subscribe((user) => (this.user = user));
  }

  public logout(): void {
    this.authRestService.logout();
  }
}
