import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";

export class SimpleResponse {
    constructor(
        public executionStatus: number,
        public executionMessage: string = ''
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class SimpleResponseAdapter implements Adapter<SimpleResponse> {
  adapt(item: any): SimpleResponse {
    return item ? new SimpleResponse(
      item.ExecutionStatus,
      item.ExecutionMessage
      ) : null;
  }
}