import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiftCardActivationSingleComponent } from './component/gift-card-activation-single/gift-card-activation-single.component';
import { GiftCardActivationComponent } from './component/gift-card-activation.component';
import { RouterModule } from '@angular/router';
import { GiftCardActivationRoutingModuleModule } from './gift-card-activation-routing-module.module';
import { SharedModule } from '@shared/shared.module';
import { GiftCardActivationElemComponent } from './component/gift-card-activation-single/gift-card-activation-elem/gift-card-activation-elem.component';
import { GiftCardActivationTableComponent } from './component/gift-card-activation-table/gift-card-activation-table.component';
import { TranslocoRootModule } from '@shared/transloco/transloco-root.module';
import { NotificationModule } from '@shared/notification';
import { NgxMaskModule } from 'ngx-mask';
import { GiftCardActivationRangeComponent } from './component/gift-card-activation-range/gift-card-activation-range.component';

@NgModule({
  declarations: [
    GiftCardActivationSingleComponent, 
    GiftCardActivationComponent, 
    GiftCardActivationElemComponent, 
    GiftCardActivationTableComponent,
    GiftCardActivationRangeComponent
  ],
  imports: [
    CommonModule,
    GiftCardActivationRoutingModuleModule,
    RouterModule,
    SharedModule,
    TranslocoRootModule,
    NotificationModule,
    NgxMaskModule.forRoot()
  ],
  exports: [GiftCardActivationComponent, RouterModule]
})
export class GiftCardActivationModule { }
