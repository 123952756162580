<app-client-search (clientListLoaded)="clientListLoaded($event)"></app-client-search>
<div class="mt-3">
    <p-table #table [value]="clientsList" [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
        currentPageReportTemplate="{{ 'features.documents.showing' | transloco }} {first} {{ 'features.documents.to' | transloco }} {last} {{ 'features.documents.of' | transloco }} {totalRecords} {{ 'features.documents.entries' | transloco }}" *ngIf="!loading" dataKey="id">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name">{{ 'features.clients.fields.name' | transloco }}
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th pSortableColumn="taxId">{{ 'features.clients.fields.taxId' | transloco }}
                    <p-sortIcon field="taxId"></p-sortIcon>
                </th>
                <th pSortableColumn="address">{{ 'features.clients.fields.address' | transloco }}
                    <p-sortIcon field="address"></p-sortIcon>
                </th>
                <th pSortableColumn="zipCode">{{ 'features.clients.fields.zipCode' | transloco }}
                    <p-sortIcon field="zipCode"></p-sortIcon>
                </th>
                <th pSortableColumn="city">{{ 'features.clients.fields.city' | transloco }}
                    <p-sortIcon field="city"></p-sortIcon>
                </th>
                <th style="width: 4.5rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-clientItem let-expanded="expanded">
            <tr>
                <td>{{ clientItem.name }}</td>
                <td>{{ clientItem.taxId }}</td>
                <td>{{ clientItem.address }}</td>
                <td>{{ clientItem.zipCode }}</td>
                <td>{{ clientItem.city }}</td>
                <td>
                    <p-button class="btn-small" icon="pi pi-angle-double-right" iconPos="center" (onClick)="onClickSelect(clientItem.id)"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>