import { NgModule } from '@angular/core';
import { GiftCardActivationComponent } from './component/gift-card-activation.component';
import { Routes, RouterModule } from '@angular/router';
// import { PrimengModule } from '@shared/primeng/primeng.module';
import { SharedModule } from '@shared/shared.module';
import { GiftCardActivationAuthGuard } from '@core/guards/gift-card-activation-auth.guard';

const routes: Routes = [
      { 
        path: 'gift-card-activation', data: { id: 'gift-card-activation' }, component: GiftCardActivationComponent,
        canLoad: [GiftCardActivationAuthGuard],
        canActivate: [GiftCardActivationAuthGuard],
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  exports: [RouterModule]
})
export class GiftCardActivationRoutingModuleModule { }

