import { Component, Input } from '@angular/core';
import { CurrentUserViewModel } from '@core/models/current-user.view-model';
import { NavItemModel } from '@core/models/nav-item.model';
import { SidebarNavHelper } from '@shared/sidebar/component/sidebar-nav/service/sidebar-nav.helper';

@Component({
  selector: 'app-sidebar-nav-dropdown',
  template: `
    <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle [class.active]="helper.isActive(item)">
      <i *ngIf="helper.hasIcon(item)" [ngClass]="helper.getIconClass(item)"></i>
      <ng-container>{{ 'core.navigation.' + item.id | transloco }}</ng-container>
      <span *ngIf="helper.hasBadge(item)" [ngClass]="helper.getBadgeClass(item)">{{ item.badge.text }}</span>
    </a>
    <ng-container *ngIf="item && item.children">
      <app-sidebar-nav-items class="nav-dropdown-items" [user]="user" [items]="item.children"></app-sidebar-nav-items>
    </ng-container>
  `,
  styles: ['.nav-dropdown-toggle { cursor: pointer; }', '.nav-dropdown-items { display: block; }'],
  providers: [SidebarNavHelper],
})
export class SidebarNavDropdownComponent {
  @Input() public item: NavItemModel;
  @Input() public user: CurrentUserViewModel;

  constructor(public helper: SidebarNavHelper) {}
}
