import { FileExtensionHelper } from '../helpers/file-extension-helper';
import { DateTime } from 'luxon';
import * as moment from 'moment';

export class DocumentElem {
    public fullName: string = "";
    public name: string = "";
    public date: DateTime = new Date();
    public extension: string = "";
    public isDownloading: boolean;

    public setFullName(fullName: string): DocumentElem {
        this.fullName = fullName;
        return this;
    }

    public setName(name: string): DocumentElem {
        this.name = name;
        return this;
    }

    public setDate(date: DateTime): DocumentElem {
        this.date = date;
        return this;
    }

    public setExtension(extension: string): DocumentElem {
        this.extension = extension; 
        return this;
    }

    public get ico() : string {
        return FileExtensionHelper
        .getInstance()
        .getIco(this.extension);
    }

    public get dateString(): string {
        return moment(this.date).format('MM/DD/YYYY');
    }

    public get monthString(): string {
        const month = this.date.getMonth() + 1;
        return month.toString();
    }

    public get yearString(): string {
        return this.date.getFullYear().toString();
    }

    public get year(): number {
        return this.date.getFullYear();
    }
}