import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentElem } from '@features/documents/model/document-elem';
import { BaseDropdownItem } from '@core/models/base-dropdown-item';
import { DocumentListFilterModel } from '@features/documents/model/document-list-filter-model';
import { ApplicationConfig } from '@core/config/application.config';
import { nameof } from '@core/helpers/extensions';
import { TableFilterConditionEnum } from '@shared/enums/table-filter-condition.enum';
import { GiftCardActivationService } from '@features/gift-card-activation/gift-card-activation.service';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { GetFileResponse } from '@features/documents/model/get-file-response';
import { GetDocumentsResponse } from '@features/documents/model/get-documents-response';
import { NotificationService } from '@shared/notification/notification.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {
  public documents: DocumentElem[] = [];
  public filterModel = new DocumentListFilterModel();
  public loading: boolean;

  @ViewChild('table') _table;
  public months: BaseDropdownItem[] = [new BaseDropdownItem('', this.translocoService.translate('common.select-month'))];
  public years: BaseDropdownItem[] = [new BaseDropdownItem('', this.translocoService.translate('common.select-year'))];

  constructor(private applicationConfig: ApplicationConfig,
    private giftCardActivationService: GiftCardActivationService,
    private notificationService: NotificationService,
    private authRestService: AuthRestService,
    private translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.fillDocuments();
  }

  private fillDocuments(): void {
    this.loading = true;
    this.giftCardActivationService.getDocuments(this.authRestService.userValue.userID).subscribe(
      (res: GetDocumentsResponse) => {
        if (res == null || res.documents == undefined) {
          this.loading = false;
          return;
        }

        for (let x = 0; x < res.documents.length; x++) {
          let doc = new DocumentElem()
          .setFullName(res.documents[x].fullName)
          .setName(res.documents[x].name)
          .setExtension(res.documents[x].extension)
          .setDate(res.documents[x].modifiedDate)
          this.documents.push(doc);
        }

        this.setAvailableYears();
        this.setAvailableMonths();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      });
  }

  public filterTableByYear(): void {
    this.filterModel.month = '';
    this.filterTableByMonth();
    this.setAvailableMonths();
    this.filterTable(this.filterModel.year, nameof<DocumentElem>('yearString'));
  }

  public filterTableByMonth(): void {
    this.filterTable(this.filterModel.month, nameof<DocumentElem>('monthString'));
  }

  private filterTable(value: string, fieldName: string, condition: TableFilterConditionEnum = TableFilterConditionEnum.equals) {
    this._table.filter(value, fieldName, condition.toString());
  }

  private getAvMonths(): BaseDropdownItem[] {
    const docs: DocumentElem[] = this.filterModel.isFilteredByYear
      ? this.documents.filter(x => x.yearString == this.filterModel.year)
      : this.documents;
      
    return docs.distinctBy<DocumentElem>(nameof<DocumentElem>('monthString'))
      .map((document: DocumentElem) => new BaseDropdownItem(document.monthString, this.applicationConfig.monthsMap.get(document.monthString)));
  }

  private getAvYears(): BaseDropdownItem[] {
    return this.documents
      .distinctBy<DocumentElem>(nameof<DocumentElem>('yearString'))
      .map((document: DocumentElem) => new BaseDropdownItem(document.yearString, document.yearString));
  }

  private setAvailableYears(): void {
    this.years = [new BaseDropdownItem('', this.translocoService.translate('common.select-year'))].concat(...this.getAvYears());
  }

  private setAvailableMonths(): void {
    this.months = [new BaseDropdownItem('', this.translocoService.translate('common.select-month'))].concat(...this.getAvMonths());
  }

  public onClickDownloadFile(doc: DocumentElem): void {
    doc.isDownloading = true;
    this.giftCardActivationService.getFile(this.authRestService.userValue.userID, doc.fullName).subscribe((res: GetFileResponse) => {
      doc.isDownloading = false;

      if (res.executionStatus != 1) {
        this.notificationService.addError(this.translocoService.translate('features.documents.errors.download'));
        return;
      }

      this.downloadFile(res.file, doc);
    });
  }

  downloadFile(base64String, file: DocumentElem) {
    const source = `data:application/octet-stream;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${file.name}.${file.extension}`
    link.click();
  }
}
