import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";
import { ClientModel, ClientModelAdapter } from "./client-model";

export class GetClientResponse {
    constructor(
        public executionStatus: number,
        public executionMessage: string = '',
        public clientList: ClientModel[]
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class GetClientResponseAdapter implements Adapter<GetClientResponse> {
  adapt(item: any): GetClientResponse {
    return item ? new GetClientResponse(
      item.data.ExecutionStatus,
      item.data.ExecutionMessage,
      item.data.ClientList?.map(i => new ClientModelAdapter().adapt(i))
      ) : null;
  }
}