import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from '@core/layout/auth/auth-layout.component';
import { LoginFormComponent } from '@features/auth/component/login-form/login-form.component';
import { RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth-routing.module';
import { InputDirective } from '@shared/input/input.directive';
import { SharedModule } from '@shared/shared.module'
import { TranslocoModule } from '@ngneat/transloco';
import { NotificationModule } from '@shared/notification';

@NgModule({
    declarations: [
        AuthLayoutComponent,
        LoginFormComponent,
        InputDirective
    ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        NotificationModule,
        SharedModule,
        TranslocoModule
    ],
    exports: [RouterModule]
})
export class AuthModule { }