import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientModel } from '@features/clients/client-model';
import { GiftCardActivationService } from '@features/gift-card-activation/gift-card-activation.service';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationService } from '@shared/notification/notification.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-client',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss']
})
export class ClientCreateComponent implements OnInit {
  public clientForm: FormGroup;
  public creatingClient: boolean;
  
  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private translateService: TranslocoService,
    private giftCardActivationService: GiftCardActivationService,
    private ref: DynamicDialogRef
  ) {
    this.clientForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      taxId: ['', [Validators.required, Validators.minLength(3)]],
      address: ['', [Validators.required, Validators.minLength(3)]],
      city: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.minLength(3)]],
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required, Validators.minLength(3)]],
      zipCode: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit(): void {
  }

  add() {
    if (this.creatingClient) {
      return;
    }

    let cm: ClientModel = Object.assign(new ClientModel(),
      this.clientForm.value
    )

    if (this.clientForm.valid) {
      this.creatingClient = true;

      this.giftCardActivationService.createClient(cm)
      .subscribe(r => {
        if (r.executionStatus === 0) {
          this.notificationService.addSuccess(this.translateService.translate('common.messages.operation-completed'), false);
          this.ref.close();
        }
        else if (r.executionStatus === 1) {
          this.notificationService.addError(this.translateService.translate('features.clients.messages.client-already-exists'), false);
        }
        else {
          this.notificationService.addError(this.translateService.translate('common.messages.operation-failed'), false);
        }
      },() => {}
      ,() => {
        this.creatingClient = false;
      });
    }
  }

  reset() {
    this.clientForm.reset();
  }
}
