<div class="col">
    <div class="row range">
        <ng-container [formGroup]="form">
            <div class="col-lg-4 col-sm-12">
                <app-form-group>
                    <input formControlName="numberFrom" 
                    appInput type="text" 
                    maxlength="256" 
                    placeholder="{{ 'features.gift-card-activation.from-card-number' | transloco }}"
                    (focusout)="checkNumbers($event)"
                    />
                </app-form-group>
            </div>
            <div class="col-lg-4 col-sm-12">
                <app-form-group>
                    <input formControlName="numberTo" 
                    appInput type="text" 
                    maxlength="256" 
                    placeholder="{{ 'features.gift-card-activation.to-card-number' | transloco }}"
                    (focusout)="checkNumbers($event)"/>
                </app-form-group>
            </div>
            <div class="col-lg-3 col-sm-12">
                <app-form-group>
                    <input formControlName="value" appInput type="text" mask="separator.2" thousandSeparator="" separatorLimit="999999" min="0" maxlength="256" placeholder="{{ 'common.enter-value' | transloco }}" (ngModelChange)="valueChanged()"/>
                </app-form-group>
            </div>
        </ng-container>
    </div>
        <div class="row">
            <div class="col-lg-6 col-sm-12"></div>
            <div class="col-lg-6 col-sm-12">
                <app-form-group>
                    <button pButton class="btn float-right" (click)="emitActivatePopupEvent($event)" label="{{ 'features.gift-card-activation.activate' | transloco }}"></button>
                </app-form-group>
            </div>
        </div>
    </div>