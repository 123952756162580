import { Injectable } from '@angular/core';
import { Adapter } from '@core/helpers/adapterInterface';

export class CurrentUser {
  constructor(
    public userID: string,
    public login: string,
    public accessToGiftCardActivation: boolean,
    public accessToCardActivationHistory: boolean,
    public accessToDocuments: boolean,
    public accessToClients: boolean
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class CurrentUserAdapter implements Adapter<CurrentUser> {
  adapt(item: any): CurrentUser {
    return new CurrentUser(
      item.ClientID,
      item.Login,
      Boolean(item.AccessToGiftCardActivation),
      Boolean(item.AccessToCardActivationHistory),
      Boolean(item.AccessToDocuments),
      Boolean(item.AccessToClients)
    );
  }
}
