import { Injectable } from '@angular/core';
import { Adapter } from '@core/helpers/adapterInterface';

export class AuthToken {
  constructor(
    public access_token: string,
    public token_type: string,
    public expires_in: string,
    public refresh_token: string
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class AuthTokenAdapter implements Adapter<AuthToken> {
  adapt(item: any): AuthToken {
    return new AuthToken(
      item.access_token,
      item.token_type,
      item.expires_in,
      item.refresh_token
    );
  }
}
