import { Component } from '@angular/core';

@Component({
  selector: 'app-sidebar-footer',
  template: `
    <div class="sidebar-footer">
      <ng-content></ng-content>
    </div>
  `,
})
export class SidebarFooterComponent {
  constructor() {}
}
