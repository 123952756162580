import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientsGuard } from '@core/guards/clients.guard';
import { SharedModule } from '@shared/shared.module';
import { ClientsComponent } from './component/clients/clients.component';

const routes: Routes = [
  { 
    path: 'clients', data: { id: 'clients' }, component: ClientsComponent,
    canLoad: [ClientsGuard],
    canActivate: [ClientsGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  exports: [RouterModule, SharedModule]
})
export class ClientsRountingModule { }
