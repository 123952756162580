import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent } from './component/login-form/login-form.component';
import { AuthLayoutComponent } from '@core/layout/auth/auth-layout.component';

const routes: Routes = [
    { path: 'auth', component: AuthLayoutComponent, data : { id : 'auth' }, children: [
        { path:'login', component: LoginFormComponent, data : { id : 'login' } }
    ] 
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
