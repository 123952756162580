import { Injectable } from '@angular/core';
import { Router, CanLoad, CanActivate } from '@angular/router';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { NotificationService } from '@shared/notification/notification.service';

@Injectable({ providedIn: 'root' })
export class GiftCardActivationAuthGuard implements CanLoad, CanActivate {
    constructor(
        private router: Router,
        private authRestService: AuthRestService,
        private notificationService: NotificationService
    ) {}

    canLoad() {
        const user = this.authRestService.userValue;
        
        if (!user) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: '' }});
            return false;
        }

        if (!user.accessToGiftCardActivation) {
            this.notificationService.addError('features.auth.login.form.messages.unauthorizedAcceess');
            return false;
        }

        // authorised so return true
        return true;
    }

    canActivate() {
        const user = this.authRestService.userValue;
        
        if (!user) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: '' }});
            return false;
        }

        if (!user.accessToGiftCardActivation) {
            this.notificationService.addError('features.auth.login.form.messages.unauthorizedAcceess');
            return false;
        }

        // authorised so return true
        return true;
    }
}