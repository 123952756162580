import { Component, OnInit, ViewChild } from '@angular/core';
import { SimpleResponse } from '@api/model/response';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { ApplicationConfig } from '@core/config/application.config';
import { nameof } from '@core/helpers/extensions';
import { BaseDropdownItem } from '@core/models/base-dropdown-item';
import { ActivationHistoryTableModel } from '@features/card-activation-history/model/activation-history-table-model';
import { CardActivationHistoryTableFilterModel } from '@features/card-activation-history/model/card-activation-history-table-filter-model';
import { GetActivationHistoryResponse } from '@features/card-activation-history/model/get-activation-history-response';
import { ClientSelectComponent } from '@features/clients/component/client-select/client-select.component';
import { GetFileResponse } from '@features/documents/model/get-file-response';
import { GiftCardActivationService } from '@features/gift-card-activation/gift-card-activation.service';
import { TranslocoService } from '@ngneat/transloco';
import { TableFilterConditionEnum } from '@shared/enums/table-filter-condition.enum';
import { NotificationService } from '@shared/notification/notification.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-card-activation-history-table',
  templateUrl: './card-activation-history-table.component.html',
  styleUrls: ['./card-activation-history-table.component.scss'],
  providers: [DialogService]
})
export class CardActivationHistoryTableComponent implements OnInit {
  public loading: boolean;
  public creatingInvoice: boolean;
  public activationHistoryList: ActivationHistoryTableModel[] = [];
  public selectedActivationHistoryList: ActivationHistoryTableModel[] = [];
  public months: BaseDropdownItem[] = [new BaseDropdownItem('', this.translocoService.translate('common.select-month'))];
  public years: BaseDropdownItem[] = [new BaseDropdownItem('', this.translocoService.translate('common.select-year'))];
  public filterModel = new CardActivationHistoryTableFilterModel();
  ref: DynamicDialogRef;
  @ViewChild('table') _table;

  constructor(private applicationConfig: ApplicationConfig,
    private giftCardActivationService: GiftCardActivationService,
    private notificationService: NotificationService,
    private authRestService: AuthRestService,
    private translocoService: TranslocoService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.fillHistory();
  }

  private fillHistory(): void {
    this.loading = true;
    
    this.giftCardActivationService.getActivationHistory(this.authRestService.userValue.userID).subscribe(
      (res: GetActivationHistoryResponse) => {
        if (res == null || res.activationHistoryList == undefined) {
          this.loading = false;
          return;
        }

        this.selectedActivationHistoryList = [];
        this.activationHistoryList = [];
        for (let x = 0; x < res.activationHistoryList.length; x++) {
          let historyItem = new ActivationHistoryTableModel()
          historyItem.cardActivationHistoryID = res.activationHistoryList[x].cardActivationHistoryID;
          historyItem.cardActivationDate = res.activationHistoryList[x].cardActivationDate;
          historyItem.batch = res.activationHistoryList[x].batch;
          historyItem.batchID = res.activationHistoryList[x].batchID;
          historyItem.quantity = res.activationHistoryList[x].quantity;
          historyItem.value = res.activationHistoryList[x].value;
          historyItem.cardList = res.activationHistoryList[x].cardList;
          historyItem.invoiceID = res.activationHistoryList[x].invoiceID;
          historyItem.invoiceName = res.activationHistoryList[x].invoiceName;
          this.activationHistoryList.push(historyItem);
        }

        this.setAvailableYears();
        this.setAvailableMonths();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      });
  }

  private setAvailableYears(): void {
    this.years = [new BaseDropdownItem('', this.translocoService.translate('common.select-year'))].concat(...this.getAvYears());
  }

  private setAvailableMonths(): void {
    this.months = [new BaseDropdownItem('', this.translocoService.translate('common.select-month'))].concat(...this.getAvMonths());
  }

  private getAvMonths(): BaseDropdownItem[] {
    const docs: ActivationHistoryTableModel[] = this.filterModel.isFilteredByYear
      ? this.activationHistoryList.filter(x => x.yearString == this.filterModel.year)
      : this.activationHistoryList;
      
    return docs.distinctBy<ActivationHistoryTableModel>(nameof<ActivationHistoryTableModel>('monthString'))
      .map((activationHistoryItem: ActivationHistoryTableModel) => new BaseDropdownItem(activationHistoryItem.monthString, this.applicationConfig.monthsMap.get(activationHistoryItem.monthString)));
  }

  private getAvYears(): BaseDropdownItem[] {
    return this.activationHistoryList
      .distinctBy<ActivationHistoryTableModel>(nameof<ActivationHistoryTableModel>('yearString'))
      .map((activationHistoryItem: ActivationHistoryTableModel) => new BaseDropdownItem(activationHistoryItem.yearString, activationHistoryItem.yearString));
  }

  public filterTableByYear(): void {
    this.filterModel.month = '';
    this.filterTableByMonth();
    this.setAvailableMonths();
    this.filterTable(this.filterModel.year, nameof<ActivationHistoryTableModel>('yearString'));
  }

  public filterTableByMonth(): void {
    this.filterTable(this.filterModel.month, nameof<ActivationHistoryTableModel>('monthString'));
  }

  private filterTable(value: string, fieldName: string, condition: TableFilterConditionEnum = TableFilterConditionEnum.equals) {
    this._table.filter(value, fieldName, condition.toString());
  }

  public onClickCreateInvoice(): void {
    this.ref = this.dialogService.open(ClientSelectComponent, {
      width: '55rem',
      header: this.translocoService.translate('features.clients.select-dialog-title')
    });

    this.ref.onClose.subscribe((clientId: string) =>{
        if (clientId) {
          this.createInvoice(clientId);
        }
    });
  }

  private createInvoice(clientId: string) {
    this.creatingInvoice = true;

    this.giftCardActivationService.createInvoice(clientId, this.selectedActivationHistoryList.map(x => x.cardActivationHistoryID))
    .subscribe((res: SimpleResponse) => {
      if (res.executionStatus != 0) {
        this.notificationService.addError(this.translocoService.translate('features.card-activation-history.errors.create-invoice'));
      }
      else {
        this.fillHistory();
        this.notificationService.addSuccess(this.translocoService.translate('common.messages.operation-completed'));
      }
    },() => {
      this.creatingInvoice = false;
    },() => {
      this.creatingInvoice = false;
    });
  }

  public onClickDownloadInvoice(model: ActivationHistoryTableModel): void {
    model.isDownloading = true;
    this.giftCardActivationService.getInvoice(model.invoiceID).subscribe((res: GetFileResponse) => {
      model.isDownloading = false;

      if (!res || res.executionStatus != 1 || !res.file) {
        this.notificationService.addError(this.translocoService.translate('features.card-activation-history.errors.download'));
        return;
      }

      this.downloadInvoice(res.file, model);
    },
    () => model.isDownloading = false);
  }

  downloadInvoice(base64String, file: ActivationHistoryTableModel) {
    const source = `data:application/octet-stream;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${file.invoiceName}.pdf`
    link.click();
  }

  public getSelectedRowsLabel() {
    return this.selectedActivationHistoryList.length === 0 ? "" : `(${this.selectedActivationHistoryList.length})`;
  }
}