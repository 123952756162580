import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";
import { GiftCardActivatedModel } from "./gift-card-activated-model";

export class CardListActivatedResponse {
    constructor(
        public ExecutionStatus: string = '',
        public ExecutionMessage: string = '',
        public CardList: GiftCardActivatedModel[]
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class CardListActivatedResponseAdapter implements Adapter<CardListActivatedResponse> {
  adapt(item: any): CardListActivatedResponse {
    return new CardListActivatedResponse(item.ExecutionStatus, item.ExecutionMessage, item.CardList);
  }
}