import { Component, OnInit } from '@angular/core';
import { ClientModel } from '@features/clients/client-model';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-client-select',
  templateUrl: './client-select.component.html',
  styleUrls: ['./client-select.component.scss']
})
export class ClientSelectComponent implements OnInit {
  public loading: boolean;
  public clientsList: ClientModel[] = [];
  
  constructor(
    private ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
  }

  public clientListLoaded(clientList: ClientModel[]) {
    this.clientsList = clientList;
  }

  public onClickSelect(clientId: string): void {
    this.ref.close(clientId);
  }
}
