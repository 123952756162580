import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent implements OnInit {
  @Input() public color = 'primary';
  @Input() public type = 'default';

  constructor() {}

  @HostBinding('class.overlay')
  public get cssClassOverlay(): boolean {
    return this.type === 'overlay';
  }

  @HostBinding('class.default')
  public get cssClassDefault(): boolean {
    return this.type === 'default';
  }

  public ngOnInit(): void {}
}
