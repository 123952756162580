import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { NavItemModel } from '@core/models/nav-item.model';
import { SidebarNavHelper } from '@shared/sidebar/component/sidebar-nav/service/sidebar-nav.helper';

@Component({
  selector: 'app-sidebar-nav-link',
  templateUrl: './link.component.html',
  providers: [SidebarNavHelper],
})
export class SidebarNavLinkComponent implements OnInit {
  @Input() public item: NavItemModel;
  public linkType: string;
  public href: string;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    public helper: SidebarNavHelper,
  ) {}

  public ngOnInit(): void {
    this.linkType = this.getLinkType();
    this.href = this.isDisabled() ? '' : this.helper.getPath(this.item);
  }

  public getLinkClass(): { 'nav-link': boolean; 'disabled': boolean; 'btn-link': boolean } {
    const disabled = this.isDisabled();
    const classes = {
      'nav-link': true,
      'disabled': disabled,
      'btn-link': disabled,
    };

    if (this.hasVariant()) {
      const variant = `nav-link-${this.item.variant}`;
      classes[variant] = true;
    }

    return classes;
  }

  public getLinkType(): string {
    return this.isExternalLink() ? 'external' : 'link';
  }

  public hasVariant(): boolean {
    return !!this.item.variant;
  }

  public isDisabled(): boolean {
    return this.item.attributes && this.item.attributes.disabled ? true : null;
  }

  public isExternalLink(): boolean {
    return (this.item.url !== undefined && this.item.url.substring(0, 4)) === 'http';
  }

  public hideMobile(): void {
    if (this.document.body.classList.contains('sidebar-show')) {
      this.renderer.removeClass(this.document.body, 'sidebar-show');
    }
  }
}
