export class FileExtensionHelper {
    public iconsMap : Map<string, string>;
    public static getInstance(): FileExtensionHelper {
        if(!this.instance)
            this.instance = new this();
        return this.instance;
    }
    private static instance: FileExtensionHelper;
    private constructor() { 
       this.iconsMap = FileExtensionPrimeNgIcoMapBuilder.getInstance().build();
    }
    public getIco(extensionName: string): string{
        try {
            return this.iconsMap.get(extensionName);
        } catch(error){
            console.error(error + ' in FileExtensionHelper.getIco()');
            return FileExtensionPrimeNgIcoMapBuilder.DEFAULT_FILE_ICO;
        }
    }
}
export class FileExtensionPrimeNgIcoMapBuilder
{
    public static readonly DEFAULT_FILE_ICO = "pi-file";
    public static getInstance(): FileExtensionPrimeNgIcoMapBuilder {
        if(!this.instance)
            this.instance = new this();
        return this.instance;
    }
    
    public build(): Map<string, string>
    {
        const map = new Map<string, string>();
        map.set('csv','pi-file-excel');
        map.set('xlsx','pi-file-excel');
        map.set('xls','pi-file-excel');
        map.set('pdf', 'pi-file-pdf');
        map.set('txt', 'pi-file');
        map.set('xml', 'pi-file');
        map.set('json', 'pi-file');
        return map;
    }

    private constructor(){ }
    private static instance: FileExtensionPrimeNgIcoMapBuilder;
}