import { Component, ElementRef, HostBinding, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserViewModel } from '@core/models/current-user.view-model';
import { NavItemModel } from '@core/models/nav-item.model';

@Component({
  selector: 'app-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
})
export class SidebarNavComponent implements OnChanges {
  @Input() public navItems: NavItemModel[];
  @Input() public user: CurrentUserViewModel;

  @HostBinding('attr.role') public role = 'nav';

  public navItemsArray: NavItemModel[];

  constructor(public router: Router, private readonly renderer: Renderer2, private readonly hostElement: ElementRef) {
    renderer.addClass(hostElement.nativeElement, 'sidebar-nav');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.navItemsArray = JSON.parse(JSON.stringify(this.navItems || []));
  }
}
