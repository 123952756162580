<form [formGroup]="clientForm">

    <div class="container">
        <div class="row">
            <div class="col-12">
                <span class="p-float-label">
                    <input pInputText type="text" id="name" formControlName="name">
                    <label for="name">{{ 'features.clients.fields.name' | transloco }}</label>
                </span>
                <div class="alert text-center" *ngIf="clientForm.get('name').dirty || clientForm.get('name').touched">
                    <div *ngIf="clientForm.get('name').invalid && clientForm.get('name').errors?.['required']">
                        {{'common.validation.required' | transloco}}
                    </div>
                    <div *ngIf="clientForm.get('name').invalid && clientForm.get('name').errors?.['minlength']">
                        {{'common.validation.min-length' | transloco}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <span class="p-float-label">
                    <input pInputText type="text" id="taxId" formControlName="taxId">
                    <label for="taxId">{{ 'features.clients.fields.taxId' | transloco }}</label>
                </span>
                <div class="alert text-center" *ngIf="clientForm.get('taxId').dirty || clientForm.get('taxId').touched">
                    <div *ngIf="clientForm.get('taxId').invalid && clientForm.get('taxId').errors?.['required']">
                        {{'common.validation.required' | transloco}}
                    </div>
                    <div *ngIf="clientForm.get('taxId').invalid && clientForm.get('taxId').errors?.['minlength']">
                        {{'common.validation.min-length' | transloco}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <span class="p-float-label">
                    <input pInputText type="text" id="firstName" formControlName="firstName">
                    <label for="firstName">{{ 'features.clients.fields.firstName' | transloco }}</label>
                </span>
                <div class="alert text-center" *ngIf="clientForm.get('firstName').dirty || clientForm.get('firstName').touched">
                    <div *ngIf="clientForm.get('firstName').invalid && clientForm.get('firstName').errors?.['required']">
                        {{'common.validation.required' | transloco}}
                    </div>
                    <div *ngIf="clientForm.get('firstName').invalid && clientForm.get('firstName').errors?.['minlength']">
                        {{'common.validation.min-length' | transloco}}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <span class="p-float-label">
                    <input pInputText type="text" id="lastName" formControlName="lastName">
                    <label for="lastName">{{ 'features.clients.fields.lastName' | transloco }}</label>
                </span>
                <div class="alert text-center" *ngIf="clientForm.get('lastName').dirty || clientForm.get('lastName').touched">
                    <div *ngIf="clientForm.get('lastName').invalid && clientForm.get('lastName').errors?.['required']">
                        {{'common.validation.required' | transloco}}
                    </div>
                    <div *ngIf="clientForm.get('lastName').invalid && clientForm.get('lastName').errors?.['minlength']">
                        {{'common.validation.min-length' | transloco}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <span class="p-float-label">
                    <input pInputText type="text" id="address" formControlName="address">
                    <label for="address">{{ 'features.clients.fields.address' | transloco }}</label>
                </span>
                <div class="alert text-center" *ngIf="clientForm.get('address').dirty || clientForm.get('address').touched">
                    <div *ngIf="clientForm.get('address').invalid && clientForm.get('address').errors?.['required']">
                        {{'common.validation.required' | transloco}}
                    </div>
                    <div *ngIf="clientForm.get('address').invalid && clientForm.get('address').errors?.['minlength']">
                        {{'common.validation.min-length' | transloco}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <span class="p-float-label">
                    <input pInputText type="text" id="zipCode" formControlName="zipCode">
                    <label for="zipCode">{{ 'features.clients.fields.zipCode' | transloco }}</label>
                </span>
                <div class="alert text-center" *ngIf="clientForm.get('zipCode').dirty || clientForm.get('zipCode').touched">
                    <div *ngIf="clientForm.get('zipCode').invalid && clientForm.get('zipCode').errors?.['required']">
                        {{'common.validation.required' | transloco}}
                    </div>
                    <div *ngIf="clientForm.get('zipCode').invalid && clientForm.get('zipCode').errors?.['minlength']">
                        {{'common.validation.min-length' | transloco}}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <span class="p-float-label">
                    <input pInputText type="text" id="city" formControlName="city">
                    <label for="city">{{ 'features.clients.fields.city' | transloco }}</label>
                </span>
                <div class="alert text-center" *ngIf="clientForm.get('city').dirty || clientForm.get('city').touched">
                    <div *ngIf="clientForm.get('city').invalid && clientForm.get('city').errors?.['required']">
                        {{'common.validation.required' | transloco}}
                    </div>
                    <div *ngIf="clientForm.get('city').invalid && clientForm.get('city').errors?.['minlength']">
                        {{'common.validation.min-length' | transloco}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <span class="p-float-label">
                    <input pInputText type="text" id="email" formControlName="email">
                    <label for="email">{{ 'features.clients.fields.email' | transloco }}</label>
                </span>
                <div class="alert text-center" *ngIf="clientForm.get('email').dirty || clientForm.get('email').touched">
                    <div *ngIf="clientForm.get('email').invalid && clientForm.get('email').errors?.['required']">
                        {{'common.validation.required' | transloco}}
                    </div>
                    <div *ngIf="clientForm.get('email').invalid && clientForm.get('email').errors?.['minlength']">
                        {{'common.validation.min-length' | transloco}}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <span class="p-float-label">
                    <input pInputText type="text" id="phone" formControlName="phone">
                    <label for="phone">{{ 'features.clients.fields.phone' | transloco }}</label>
                </span>
                <div class="alert text-center" *ngIf="clientForm.get('phone').dirty || clientForm.get('phone').touched">
                    <div *ngIf="clientForm.get('phone').invalid && clientForm.get('phone').errors?.['required']">
                        {{'common.validation.required' | transloco}}
                    </div>
                    <div *ngIf="clientForm.get('phone').invalid && clientForm.get('phone').errors?.['minlength']">
                        {{'common.validation.min-length' | transloco}}
                    </div>
                </div>
            </div>
        </div>
    </div>
            
    <div class="container mt-5 d-flex justify-content-between">
        <button class="p-button p-component p-element btn" type="button" value="reset" (click)="reset()">{{ 'features.clients.reset' | transloco }}</button>
        <p-button 
        label="{{ 'features.clients.add' | transloco }}"
        type="submit" value="submit"
        (onClick)="add()"
        [disabled]="!clientForm.valid">
        <i class="pi pi-spin pi-spinner spinner-sm" *ngIf="creatingClient"></i>
    </p-button>
    </div>

</form>