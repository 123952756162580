<p-table [value]="transactions">
    <ng-template pTemplate="header">
        <tr>
            <th>{{ 'features.gift-card-activation.table.fields.transaction-number' | transloco }}</th>
            <th>{{ 'features.gift-card-activation.table.fields.value' | transloco }}</th>
            <th>{{ 'features.gift-card-activation.table.fields.batch' | transloco }}</th>
            <th style="width: 9rem">{{ 'features.gift-card-activation.table.fields.template' | transloco }}</th>
            <th>{{ 'features.gift-card-activation.table.fields.shippingAddress' | transloco }}</th>
            <th>{{ 'features.gift-card-activation.table.fields.card-number' | transloco }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-card>
        <tr>
            <td>{{ card.transactionNumber }}</td>
            <td>{{ card.transactionValue }}</td>
            <td>{{ card.batch }}</td>
            <td>
                <div *ngIf="card.graphicUrl" class="p-shadow-4" style="text-align: center; width: 100%;">
                    <p-image src={{card.graphicUrl}} alt="Image" width="75%" height="100%" [preview]="true"></p-image>
                </div>
            </td>
            <td>{{ card.shippingAddress }}</td>
            <td>
                <input [(ngModel)]="card.cardNumber" appInput type="text" maxlength="256"
                    placeholder="{{ 'features.gift-card-activation.enter-card-number' | transloco }}" style="width: 100%;"
                    [class.redBorder] = "card.canActivate == false"/>
            </td>
        </tr>
    </ng-template>
</p-table>
<app-form-group>
    <button pButton class="btn float-right p-m-2" label="{{ 'features.gift-card-activation.activate' | transloco }}" (click)="emitActivatePopupEvent($event)"></button>
</app-form-group>