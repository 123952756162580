<div class="auth-layout">
  <div class="brand"></div>
  <main class="main container">
    <p-messages></p-messages>

    <div class="wrapper">
      <router-outlet></router-outlet>
    </div>
    <footer class="auth-footer">
      <div class="logo-container"></div>
      <div class="url-container">
        <a class="link" href="https://positivecinema.com">www.positivecinema.com</a>
        <a class="link" href="https://lsisoftware.pl">www.lsisoftware.pl</a>
      </div>
      <span class="copyright-container">&copy; LSI Software S.A. {{ 2023 }}</span>
    </footer>
  </main>
</div>