import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";

export class ClientModel {
    constructor(
        public id: string = '',
        public name: string = '',
        public taxId: string = '',
        public firstName: string = '',
        public lastName: string = '',
        public phone: string = '',
        public email: string = '',
        public address: string = '',
        public zipCode: string = '',
        public city: string = ''
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class ClientModelAdapter implements Adapter<ClientModel> {
  adapt(item: any): ClientModel {
    return item ? new ClientModel(
        item.ID,
        item.Name, 
        item.TaxID,
        item.FirstName,
        item.LastName,
        item.Phone,
        item.Email,
        item.Address,
        item.ZipCode,
        item.City,
        ) : null;
  }
}