import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";

export class GiftCardModel {
    constructor(
        public cardNumber: string = '',
        public cardValue: number,
        public batchID: string = ''
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class GiftCardModelAdapter implements Adapter<GiftCardModel> {
  adapt(item: any): GiftCardModel {
    return item ? new GiftCardModel(
      item.Number, 
      item.Value ? item.Value.toFixed(2) : null,
      item.BatchID
      ) : null;
  }
}