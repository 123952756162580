import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";
import { GiftCardModel, GiftCardModelAdapter } from "./gift-card-model";

export class GiftCardRangeResponse {
    constructor(
        public executionStatus: number,
        public cards: GiftCardModel[]
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class GiftCardRangeResponseAdapter implements Adapter<GiftCardRangeResponse> {
  adapt(item: any): GiftCardRangeResponse {
    return item ? new GiftCardRangeResponse(
      item.ExecutionStatus,
      item.CardList?.map(i => new GiftCardModelAdapter().adapt(i))
      ) : null;
  }
}