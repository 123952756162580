import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { GiftCardActivationService } from '@features/gift-card-activation/gift-card-activation.service';
import { GiftCardActivationModel } from '@features/gift-card-activation/model/gift-card-activation.model';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationService } from '@shared/notification/notification.service';

@Component({
  selector: 'app-gift-card-activation-range',
  templateUrl: './gift-card-activation-range.component.html',
  styleUrls: ['./gift-card-activation-range.component.scss']
})
export class GiftCardActivationRangeComponent implements OnInit {
  @Output() activatePopupEvent: EventEmitter<any> = new EventEmitter();
  public form: FormGroup;
  public cards: GiftCardActivationModel[] = [];
  private authService: AuthRestService;

  constructor(private readonly fb : FormBuilder,
    private giftCardActivationService: GiftCardActivationService,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
    private authRestService: AuthRestService) {
      this.authService = authRestService;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      numberFrom: [null, Validators.required], 
      numberTo: [null, Validators.required], 
      value: [null]
    });
  }

  public emitActivatePopupEvent($event: Event): void {
    let sum: number = this.getValidCards().map(card => Math.round((Number(card.value) + Number.EPSILON) * 100) / 100).reduce((total, num) => total + num);

    if (isNaN(sum)) {
      sum = 0;
    }

    let valuesSum: string = sum.toFixed(2);
    
    if (this.cards && this.getValidCards().length > 0)
    {
      this.activatePopupEvent.emit({
          event: $event,
          cardsAmount: this.getValidCards().length,
          valuesSum: valuesSum
        }
      );
    }
  }

  public valueChanged() {
    const numberFrom = this.form.get('numberFrom').value;
    const numberTo = this.form.get('numberTo').value;

    this.UpdateValue();
  }

  public checkNumbers($event) {
    this.cards = [];
    const numberFrom = this.form.get('numberFrom').value;
    const numberTo = this.form.get('numberTo').value;
    
    if (numberFrom && numberTo) {
      this.giftCardActivationService.getCardRange(numberFrom, numberTo, this.authService.userValue.userID).subscribe((res) => {
        if (!res || res.executionStatus === 1) {
          this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.card-not-exists'));
          this.form.reset();
          return;
        }

        if (res.executionStatus === 2) {
          this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.cards-from-different-batches'));
          this.form.reset();
          return;
        }

        if (res.executionStatus === 3) {
          this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.cannot-activate-card'));
          this.form.reset();
          return;
        }

        if (!res.cards) {
          this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.no-cards-to-activate'));
          this.form.reset();
          return;
        }

        if (res.cards[0].cardValue) {
          this.form.get('value').patchValue(res.cards[0].cardValue, {emitEvent: true});
          this.form.get('value').disable();
        } else {
          this.form.get('value').patchValue(null, {emitEvent: true});
          this.form.get('value').enable();
        }

        res.cards.forEach(c => this.cards.push(new GiftCardActivationModel(c.cardNumber, '', c.cardValue, true)))
        this.cards.forEach(c => c.canActivate = true)

        this.UpdateValue();
        }
      );
    }
  }

  public UpdateValue() {
    this.cards.forEach(c => {
      if (!this.form.get('value').value || this.form.get('value').value < 0)
        c.value = 0;

      else
        c.value = this.form.get('value').value;
    });
  }

  public clearForm () {
    this.form.reset();
    this.cards = [];
  }

  public getValidCards(): GiftCardActivationModel[] {
    return this.cards.filter(c => c.canActivate);
  }
}