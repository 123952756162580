<h2>{{ 'features.clients.clients' | transloco }}</h2>
<div class="col-12 d-flex justify-content-between align-items-center p-mb-3 p-0 mx-0 my-4">
    <app-client-search (clientListLoaded)="clientListLoaded($event)"></app-client-search>
    <div class="p-m-2 col-sm-12 col-md-6 col-lg-2" *ngIf="loading">
        <i class="pi pi-spin pi-spinner spinner"></i>
    </div>
    <div>
        <p-button class="btn float-right" label="{{ 'features.clients.new' | transloco }}" (click)="showAddClientDialog()"></p-button>
    </div>
</div>
<p-table #table [value]="clientsList" [paginator]="true" [rows]="25" [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'features.documents.showing' | transloco }} {first} {{ 'features.documents.to' | transloco }} {last} {{ 'features.documents.of' | transloco }} {totalRecords} {{ 'features.documents.entries' | transloco }}" [rowsPerPageOptions]="[25,50,100]" sortField="historyDate" *ngIf="!loading" dataKey="id">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="name">{{ 'features.clients.fields.name' | transloco }}
                <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="taxId">{{ 'features.clients.fields.taxId' | transloco }}
                <p-sortIcon field="taxId"></p-sortIcon>
            </th>
            <th pSortableColumn="address">{{ 'features.clients.fields.address' | transloco }}
                <p-sortIcon field="address"></p-sortIcon>
            </th>
            <th pSortableColumn="zipCode">{{ 'features.clients.fields.zipCode' | transloco }}
                <p-sortIcon field="zipCode"></p-sortIcon>
            </th>
            <th pSortableColumn="city">{{ 'features.clients.fields.city' | transloco }}
                <p-sortIcon field="city"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-clientItem let-expanded="expanded">
        <tr>
            <td>{{ clientItem.name }}</td>
            <td>{{ clientItem.taxId }}</td>
            <td>{{ clientItem.address }}</td>
            <td>{{ clientItem.zipCode }}</td>
            <td>{{ clientItem.city }}</td>
        </tr>
    </ng-template>
</p-table>
