import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { EditableRow, TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { TabMenuModule } from 'primeng/tabmenu';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ImageModule } from 'primeng/image';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DialogModule,
    DropdownModule,
    DynamicDialogModule,
    InputSwitchModule,
    InputTextModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OverlayPanelModule,
    RadioButtonModule,
    SelectButtonModule,
    SliderModule,
    TableModule,
    ToastModule,
    TooltipModule,
    TreeTableModule,
    ChipsModule,
    KeyFilterModule,
    InputTextareaModule,
    PaginatorModule,
    CardModule,
    InplaceModule,
    InputMaskModule,
    DragDropModule,
    AccordionModule,
    InputNumberModule,
    MenuModule,
    EditorModule,
    TabViewModule,
    CheckboxModule,
    ChartModule,
    FileUploadModule,
    MenubarModule,
    SidebarModule,
    TabMenuModule,
    ConfirmPopupModule,
    ImageModule
  ],
  exports: [
    AutoCompleteModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DialogModule,
    DropdownModule,
    DynamicDialogModule,
    InputSwitchModule,
    InputTextModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OverlayPanelModule,
    RadioButtonModule,
    SelectButtonModule,
    SliderModule,
    TableModule,
    ToastModule,
    TooltipModule,
    TreeTableModule,
    ChipsModule,
    KeyFilterModule,
    InputTextareaModule,
    PaginatorModule,
    CardModule,
    InplaceModule,
    InputMaskModule,
    DragDropModule,
    AccordionModule,
    InputNumberModule,
    MenuModule,
    EditorModule,
    TabViewModule,
    CheckboxModule,
    ChartModule,
    FileUploadModule,
    MenubarModule,
    SidebarModule,
    TabMenuModule,
    ConfirmPopupModule,
    ImageModule
  ],
  providers: [EditableRow, ConfirmationService],
})
export class PrimengModule {}
