<div class="p-input-group">
    <ng-content></ng-content>
</div>      

<!-- TODO call below rendering -->
<!-- <ng-container *transloco="let translation">
    <div class="p-input-group">
      <ng-container *ngIf="icon !== null">
        <div class="p-input-group-icon">
          <ng-container *ngTemplateOutlet="inputContent"></ng-container>
          <ng-container *ngIf="!isRadioButton; then labelContent"></ng-container>
          <span class="pi pi-{{ icon }} p-form-control-icon p-form-control-icon-append"></span>
        </div>
      </ng-container>
  
      <ng-container *ngIf="icon === null">
        <ng-container *ngTemplateOutlet="inputContent"></ng-container>
        <ng-container *ngIf="!isRadioButton; then labelContent"></ng-container>
      </ng-container>
  
      <ng-container *ngIf="hint !== null">
        <span class="form-text text-muted">{{ translation(hint) }}</span>
      </ng-container>
      <ng-content select="[custom-error]"></ng-content>
    </div>
  
    <ng-template #inputContent>
      <ng-content></ng-content>
    </ng-template>
  
    <ng-template #labelContent>
      <label *ngIf="label; else inputLabel" class="p-label">
        {{ translation(label) }}
      </label>
    </ng-template>
  
    <ng-template #inputLabel>
      <label *ngIf="labelText" class="p-label">
        {{ labelText }}
      </label>
    </ng-template> -->
  <!-- </ng-container> -->