import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNavDropdown]',
})
export class NavDropdownDirective {
  constructor(private readonly el: ElementRef) {}

  public toggle(): void {
    this.el.nativeElement.classList.toggle('open');
  }
}

@Directive({
  selector: '[appNavDropdownToggle]',
})
export class NavDropdownToggleDirective {
  constructor(private readonly dropdown: NavDropdownDirective) {}

  @HostListener('click', ['$event'])
  public toggleOpen($event: Event): void {
    $event.preventDefault();
    this.dropdown.toggle();
  }
}
