import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationModel } from '@shared/notification/notification.model';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
       private readonly messagesService: MessageService,
       private readonly translateService: TranslocoService
     ) { }

  public add(message: NotificationModel): void {
    this.clear();
    this.messagesService.add(message);
    setTimeout(() => {
      this.clear();
    }, 3000);
  }

  public clear(): void {
    this.messagesService.clear();
  }

  public addSuccess(message: string, translate: boolean = true): void {
    translate ?
      this.translateService.selectTranslate(message).subscribe((text: string) => {
        this.add({
          severity: 'success',
          summary: this.translateService.translate(text)
        });
      }) : this.add({ severity: 'success', summary: message });
  }

  public addError(message: string, translate: boolean = true): void {
    translate ?
      this.translateService.selectTranslate(message).subscribe((text: string) => {
        this.add({
          severity: 'error',
          summary: this.translateService.translate(text)
        });
      }) : this.add({ severity: 'error', summary: message });
  }
}
