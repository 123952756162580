import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";

export class GiftCardActivatedModel {
    constructor(
        public ExecutionStatus: string = '',
        public ExecutionMessage: string = '',
        public CardOperationID: string = '',
        public CardNumber: string = ''
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class GiftCardActivatedModelAdapter implements Adapter<GiftCardActivatedModel> {
  adapt(item: any): GiftCardActivatedModel {
    return new GiftCardActivatedModel(item.ExecutionStatus, item.ExecutionMessage, item.CardOperationID, item.CardNumber);
  }
}