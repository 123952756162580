import * as moment from 'moment';
import { ActivationHistoryCardModel } from './activation-history-card-model';

export class ActivationHistoryTableModel {
    public cardActivationHistoryID: string = '';
    public batchID: string = '';
    public batch: string = '';
    public quantity: number;
    public value: number;
    public cardActivationDate: Date;
    public cardList: ActivationHistoryCardModel[]
    public invoiceID: string = ''
    public invoiceName: string = ''
    public isDownloading: boolean;

    public get dateString(): string {
        let locale = localStorage.getItem('language');
        return this.cardActivationDate.toLocaleString(locale);
    }

    public get monthString(): string {
        const month = this.cardActivationDate.getMonth() + 1;
        return month.toString();
    }

    public get yearString(): string {
        return this.cardActivationDate.getFullYear().toString();
    }

    public get year(): number {
        return this.cardActivationDate.getFullYear();
    }
}