import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientModel } from '@features/clients/client-model';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ClientCreateComponent } from '../client-create/client-create.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  providers: [DialogService]
})
export class ClientsComponent implements OnInit {
  public loading: boolean;
  public clientsList: ClientModel[] = [];
  ref: DynamicDialogRef;

  constructor(
    private dialogService: DialogService,
    private translocoService: TranslocoService) {
  }

  ngOnInit(): void {
  }

  showAddClientDialog() {
    this.ref = this.dialogService.open(ClientCreateComponent, {
      width: '30rem',
      header: this.translocoService.translate('features.clients.add-dialog-title')
    });
  }

  ngOnDestroy() {
    if (this.ref) {
        this.ref.close();
    }
  }

  public clientListLoaded(clientList: ClientModel[]) {
    this.clientsList = clientList;
  }
}
