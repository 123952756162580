import { Component, OnInit, Output } from '@angular/core';
import { GiftCardActivationTableElemModel } from '@features/gift-card-activation/model/gift-card-activation-table-elem-model';
import { EventEmitter } from '@angular/core';
import { GiftCardActivationService } from '@features/gift-card-activation/gift-card-activation.service';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { tap } from 'rxjs/internal/operators/tap';
import { from } from 'rxjs/internal/observable/from';
import { concatMap, toArray } from 'rxjs/operators';
import { NotificationService } from '@shared/notification/notification.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-gift-card-activation-table',
  templateUrl: './gift-card-activation-table.component.html',
  styleUrls: ['./gift-card-activation-table.component.scss']
})
export class GiftCardActivationTableComponent implements OnInit {
  @Output() activatePopupEvent: EventEmitter<any> = new EventEmitter();
  public transactions : GiftCardActivationTableElemModel[];

  constructor(private giftCardActivationService : GiftCardActivationService,
              private authRestService: AuthRestService,
              private notificationService: NotificationService,
              private translocoService: TranslocoService) {
  }

  ngOnInit(): void {
    this.fillModel();
    this.giftCardActivationService.completed$.subscribe((cardNumbers) => {
      if (cardNumbers) {
        this.transactions = this.transactions.filter(o => !cardNumbers.includes(o.cardNumber));
      }
    });
  }

  public emitActivatePopupEvent($event: Event): void {
    this.checkCards().subscribe(_ => {
      if (this.allTransactionsValid()) {
        let sum: number = this.getValidTransactions().map(card => Math.round((Number(card.transactionValue) + Number.EPSILON) * 100) / 100).reduce((total, num) => total + num);

        if (isNaN(sum)) {
          sum = 0;
        }
        
        let valuesSum: string = sum.toFixed(2);

        this.activatePopupEvent.emit(
          { 
            event: $event, 
            cardsAmount: this.getValidTransactions().length, 
            valuesSum: valuesSum
          }
        );
      }
    });
  }

  private fillModel() : void {
    this.giftCardActivationService.getTransactions(this.authRestService.userValue.userID).subscribe((data: any[]) => {
      this.transactions = data;
    });
  }

  private allTransactionsValid(): boolean {  
    if (this.getFilledTransactions().filter(c => c.executionStatus == 1).length > 0) {
      this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.card-already-activated'));
      return false;
    }

    if (this.getFilledTransactions().filter(c => c.executionStatus == 3).length > 0) {
      this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.card-not-exists'));
      return false;
    }

    if (this.getFilledTransactions().filter(c => c.executionStatus != 0).length > 0) {
      this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.cannot-activate-card'));
      return false;
    }

    return this.getFilledTransactions().filter(c => c.canActivate).length > 0;
  }

  private getFilledTransactions(): GiftCardActivationTableElemModel[] {
    return this.transactions.filter(t => t.cardNumber !== '' && t.cardNumber !== null);
  }

  private checkCards() {
    for (let t of this.transactions) {
      t.canActivate = null;
      t.executionStatus = 0;
    }
    
    return from(this.getFilledTransactions()).pipe(
      concatMap(card => {
        return this.giftCardActivationService.getCard(card.cardNumber, this.authRestService.userValue.userID, card.batchID).pipe(
          tap((res) => {
            card.executionStatus = res.executionStatus;
            card.canActivate = (res && this.transactions.filter(x => x.cardNumber == card.cardNumber).length == 1) && (res.executionStatus == 0) && res.cards !== undefined && res.cards[0].batchID == card.batchID;
          })
        );
      }),
      toArray()
    )
  }

  public getValidTransactions() {
    return this.transactions.filter(t => t.canActivate);
  }
}