import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";
import { DocumentModel, DocumentModelAdapter } from "./document-model";

export class GetDocumentsResponse {
    constructor(
        public errormessage: string,
        public executionStatus: number,
        public documents: DocumentModel[]
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class GetDocumentsResponseAdapter implements Adapter<GetDocumentsResponse> {
  adapt(item: any): GetDocumentsResponse {
    return item ? new GetDocumentsResponse(
      item.ErrorMessage,
      item.Status,
      item.Collection?.map(i => new DocumentModelAdapter().adapt(i))
      ) : null;
  }
}