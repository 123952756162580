import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  GiftCardActivationTableElemModel,
  GiftCardActivationTableElemModelAdapter,
} from 'src/app/features/gift-card-activation/model/gift-card-activation-table-elem-model';
import { map } from 'rxjs/operators';
import {
  CurrentUser,
  CurrentUserAdapter,
} from '@features/auth/model/current-user';
import {
  GiftCardActivatedModel,
  GiftCardActivatedModelAdapter,
} from '@features/gift-card-activation/model/gift-card-activated-model';
import {
  GiftCardRangeResponse,
  GiftCardRangeResponseAdapter,
} from '@features/gift-card-activation/model/gift-card-range-response';
import { Config } from '@core/config/config.service';
import {
  GetDocumentsResponse,
  GetDocumentsResponseAdapter,
} from '@features/documents/model/get-documents-response';
import { GetFileResponseAdapter } from '@features/documents/model/get-file-response';
import {
  GiftCardResponse,
  GiftCardResponseAdapter,
} from '@features/gift-card-activation/model/gift-card-response';
import { GiftCardActivationModel } from '@features/gift-card-activation/model/gift-card-activation.model';
import {
  CardListActivatedResponse,
  CardListActivatedResponseAdapter,
} from '@features/gift-card-activation/model/card-list-activated-response';
import {
  GetActivationHistoryResponse,
  GetActivationHistoryResponseAdapter,
} from '@features/card-activation-history/model/get-activation-history-response';
import { SimpleResponse, SimpleResponseAdapter } from '@api/model/response';
import {
  GetClientResponse,
  GetClientResponseAdapter,
} from '@features/clients/get-client-response';
import {
  ClientModel,
  ClientModelAdapter,
} from '@features/clients/client-model';
import {
  CreateClientResponse,
  CreateClientResponseAdapter,
} from '@features/clients/create-client.response';
import { AuthToken, AuthTokenAdapter } from '@features/auth/model/auth-token';

@Injectable({
  providedIn: 'root',
})
export class GiftCardActivationRestService {
  config: Config;

  constructor(private httpClient: HttpClient) {
    this.fetchConfig();
  }

  fetchConfig(): void {
    let configTemp: Config;

    fetch('./assets/config.json')
      .then((res) => res.json())
      .then((config: Config) => {
        configTemp = config;
      });

    let localStorageConfig: Config;
    let s: any = localStorage.getItem('serviceConfig');

    if (s != undefined) {
      localStorageConfig = JSON.parse(s);
    }

    if (configTemp != undefined) {
      this.config = configTemp;
      localStorage.setItem('serviceConfig', JSON.stringify(configTemp));
    } else if (localStorageConfig != undefined) {
      this.config = localStorageConfig;
      localStorage.setItem('serviceConfig', JSON.stringify(localStorageConfig));
    }

    fetch('./assets/config.json')
      .then((res) => res.json())
      .then((config: Config) => {
        this.config = config;
        localStorage.setItem('serviceConfig', JSON.stringify(config));
      });
  }

  public getTransactions(
    userID: string
  ): Observable<GiftCardActivationTableElemModel[]> {
    return this.httpClient
      .get(this.config.apiUrl + 'GetTransactions.json', {
        params: { clientID: userID },
      })
      .pipe(
        map((data: any) => {
          if (data.data.length) {
            return data.data.map((item) =>
              new GiftCardActivationTableElemModelAdapter().adapt(item)
            );
          } else {
            return [
              new GiftCardActivationTableElemModelAdapter().adapt(data.data),
            ];
          }
        })
      );
  }

  public auth(login: string, password: string): Observable<AuthToken> {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/json'
    );

    const body: Object = {};
    body['Login'] = login;
    body['Password'] = password;

    return this.httpClient
      .post(this.config.apiUrl + 'Auth.json', JSON.stringify(body), {
        headers: headers,
      })
      .pipe(map((data: any) => new AuthTokenAdapter().adapt(data.data)));
  }

  public activateCard(
    cardNumber: string,
    cardOperationID: string,
    value: Number
  ): Observable<GiftCardActivatedModel> {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    let body = new HttpParams();
    body = body.set('CardNumber', cardNumber);
    body = body.set('CardOperationID', cardOperationID);
    body = body.set('Value', value.toString());

    return this.httpClient
      .post(this.config.apiUrl + 'CardActivation.json', body, {
        headers: headers,
      })
      .pipe(
        map((data: any) => new GiftCardActivatedModelAdapter().adapt(data.data))
      );
  }

  public activateCardList(
    cardList: GiftCardActivationModel[],
    userID: string
  ): Observable<CardListActivatedResponse> {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    let body = new HttpParams();
    body = body.set('CardList', JSON.stringify(cardList));
    body = body.set('ClientID', userID);

    return this.httpClient
      .post(this.config.apiUrl + 'CardListActivation.json', body, {
        headers: headers,
      })
      .pipe(
        map((data: any) =>
          new CardListActivatedResponseAdapter().adapt(data.data)
        )
      );
  }

  public getCard(
    cardNumber: string,
    userID: string,
    batchID: string
  ): Observable<GiftCardResponse> {
    return this.httpClient
      .get(this.config.apiUrl + 'GetCard.json', {
        params: { cardNumber: cardNumber, clientID: userID, batchID: batchID },
      })
      .pipe(map((data: any) => new GiftCardResponseAdapter().adapt(data.data)));
  }

  public getCardRange(
    cardNumberFrom: string,
    cardNumberTo: string,
    userID: string
  ): Observable<GiftCardRangeResponse> {
    return this.httpClient
      .get(this.config.apiUrl + 'GetCardRange.json', {
        params: {
          cardNumberFrom: cardNumberFrom,
          cardNumberTo: cardNumberTo,
          clientID: userID,
        },
      })
      .pipe(
        map((data: any) => new GiftCardRangeResponseAdapter().adapt(data.data))
      );
  }

  public getFtpFiles(userID: string): Observable<GetDocumentsResponse> {
    return this.httpClient
      .get(this.config.apiUrl + 'GetFtpFiles.json', {
        params: { userID: userID },
      })
      .pipe(
        map((data: any) => new GetDocumentsResponseAdapter().adapt(data?.Data))
      );
  }

  public getFile(userID: string, filename: string): Observable<any> {
    return this.httpClient
      .get(this.config.apiUrl + 'GetFile.json', {
        params: { userID: userID, filename: filename },
      })
      .pipe(map((data: any) => new GetFileResponseAdapter().adapt(data)));
  }

  public getActivationHistory(
    userID: string
  ): Observable<GetActivationHistoryResponse> {
    return this.httpClient
      .get(this.config.apiUrl + 'GetActivationHistory.json', {
        params: { clientID: userID },
      })
      .pipe(
        map((data: any) =>
          new GetActivationHistoryResponseAdapter().adapt(data)
        )
      );
  }

  public createInvoice(
    clientId: string,
    cardActivationHistoryIDList: string[]
  ): Observable<SimpleResponse> {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    let body = new HttpParams();
    body = body.set(
      'CardActivationHistoryIDList',
      JSON.stringify(cardActivationHistoryIDList)
    );
    body = body.set('ClientID', clientId);

    return this.httpClient
      .post(this.config.apiUrl + 'CreateInvoice.json', body, {
        headers: headers,
      })
      .pipe(map((data: any) => new SimpleResponseAdapter().adapt(data.data)));
  }

  public getInvoice(invoiceID: string): Observable<any> {
    return this.httpClient
      .get(this.config.apiUrl + 'GetInvoice.json', {
        params: { invoiceID: invoiceID },
      })
      .pipe(map((data: any) => new GetFileResponseAdapter().adapt(data)));
  }

  public getClient(name: string, taxId: string): Observable<GetClientResponse> {
    return this.httpClient
      .get(this.config.apiUrl + 'GetClient.json', {
        params: { name: name, taxId: taxId },
      })
      .pipe(map((data: any) => new GetClientResponseAdapter().adapt(data)));
  }

  public addClient(
    name: string,
    taxId: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    address: string,
    zipCode: string,
    city: string
  ): Observable<ClientModel> {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    let body = new HttpParams();
    body = body.set('Name', name);
    body = body.set('TaxID', taxId);
    body = body.set('FirstName', firstName);
    body = body.set('LastName', lastName);
    body = body.set('Phone', phone);
    body = body.set('Email', email);
    body = body.set('Address', address);
    body = body.set('ZipCode', zipCode);
    body = body.set('City', city);

    return this.httpClient
      .post(this.config.apiUrl + 'CreateClient.json', body, {
        headers: headers,
      })
      .pipe(map((data: any) => new ClientModelAdapter().adapt(data.data)));
  }

  public createClient(client: ClientModel): Observable<CreateClientResponse> {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    let body = new HttpParams();
    body = body.set('Name', client.name);
    body = body.set('TaxID', client.taxId);
    body = body.set('FirstName', client.firstName);
    body = body.set('LastName', client.lastName);
    body = body.set('Phone', client.phone);
    body = body.set('Email', client.email);
    body = body.set('Address', client.address);
    body = body.set('ZipCode', client.zipCode);
    body = body.set('City', client.city);

    return this.httpClient
      .post(this.config.apiUrl + 'CreateClient.json', body, {
        headers: headers,
      })
      .pipe(
        map((data: any) => new CreateClientResponseAdapter().adapt(data?.data))
      );
  }
}
