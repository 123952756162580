import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { NotificationService } from '@shared/notification/notification.service';

@Component({
  selector: 'app-auth-login',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  public form: FormGroup;
  public loading: boolean = false;
  public submitted: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authRestService: AuthRestService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) return;

    this.loading = true;
    this.authRestService
      .login(this.f.login.value, this.f.password.value)
      .pipe(map((t) => this.authRestService.getUserFromToken(t.access_token)))
      .subscribe({
        next: (u) => {
          //TODO add redirection for correct route based on data from API
          this.loading = false;
          const returnUrl: string =
            this.route.snapshot.queryParams['returnUrl'] ||
            (u.accessToGiftCardActivation
              ? '/app/gift-card-activation'
              : u.accessToDocuments
              ? '/app/documents'
              : undefined);
          this.router.navigateByUrl(returnUrl);
        },
        error: () => {
          this.loading = false;
          this.notificationService.addError(
            'features.auth.login.form.messages.failed'
          );
        },
      });
  }

  public hasErrorInField(fieldName: string): unknown {
    return this.submitted && this.f[fieldName].errors;
  }
}
