import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { ModalComponent } from './shared/component/modal/modal.component';
import { AuthModule } from '@features/auth/auth.module'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor } from '@api/interceptor/rest/jwt.interceptor';
import { AppLayoutComponent } from '@core/layout/app/app-layout.component';
import { TranslocoRootModule } from '@shared/transloco/transloco-root.module';
import { GiftCardActivationModule } from '@features/gift-card-activation/gift-card-activation.module';
import { SharedModule } from '@shared/shared.module';
import { SidebarModule } from '@shared/sidebar/sidebar.module'
import { IconModule } from '@shared/icon';
import { DocumentsModule } from '@features/documents/documents.module';
import { ApplicationConfig } from '@core/config/application.config';
import { MessageService } from 'primeng/api/';
import { Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@environments/environment';
import { CardActivationHistoryModule } from '@features/card-activation-history/card-activation-history.module';
import { ClientsModule } from '@features/clients/clients.module';

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    ModalComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    HttpClientModule,
    TranslocoRootModule,
    AppRoutingModule,
    GiftCardActivationModule,
    SidebarModule,
    IconModule,
    DocumentsModule,
    CardActivationHistoryModule,
    ClientsModule
  ],
  exports: [SharedModule, SidebarModule, IconModule],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFactory,
    //   deps: [TranslocoService, Injector],
    //   multi: true
    // },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    ApplicationConfig,
    MessageService
],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function appInitializerFactory(translate: TranslocoService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = localStorage.getItem('language');
      translate.setDefaultLang(environment.defaultLocale);
      translate.setActiveLang(langToSet);
      // .subscribe(() => {
      //   console.info(`Successfully initialized '${langToSet}' language.'`);
      // }, err => {
      //   console.error(`Problem with '${langToSet}' language initialization.'`);
      // }, () => {
      //   resolve(null);
      // });
    });
  });
}
