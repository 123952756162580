import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentElemComponent } from './component/document-elem/document-elem.component';
import { DocumentListComponent } from './component/document-list/document-list.component';
import { DocumentsRoutingModule } from './documents-routing.module';
import { TranslocoRootModule } from '@shared/transloco/transloco-root.module';
import { SharedModule } from 'primeng/api';

@NgModule({
  declarations: [DocumentElemComponent, DocumentListComponent],
  imports: [
    CommonModule,
    SharedModule,
    DocumentsRoutingModule,
    TranslocoRootModule
  ],
  exports: [DocumentElemComponent, DocumentListComponent, DocumentsRoutingModule, SharedModule]
})
export class DocumentsModule { }
