<app-sidebar [fixed]="true" [minimized]="true" [display]="'md'">
  <div class="brand-minimized">
    <div class="logo-container"></div>
  </div>
  <app-sidebar-nav [navItems]="menuItems"></app-sidebar-nav>
  <app-sidebar-footer>
    <ul class="nav">
      <li class="nav-item">
        <button pButton pTooltip="{{ 'shared.sidebar.footer.settings' | transloco }}" (click)="settings()" icon="pi pi-cog" class="navFooterButton"></button>
      </li>
      <li class="nav-item">
        <button pButton pTooltip="{{ 'shared.sidebar.footer.logout' | transloco }}" (click)="logout()" icon="pi pi-sign-out" class="navFooterButton"></button>
      </li>
    </ul>
  </app-sidebar-footer>
</app-sidebar>
<div class="row messages-container text-center w-100">
  <p-messages class="col pl-4 pr-0 ml-0 ml-sm-5"></p-messages>
</div>
<div style="padding: 2rem; margin-left: 40px; margin-right: 40px;">
  <div class="row">
    <div class="col-sm-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>