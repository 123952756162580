import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientModel } from '@features/clients/client-model';
import { GetClientResponse } from '@features/clients/get-client-response';
import { GiftCardActivationService } from '@features/gift-card-activation/gift-card-activation.service';

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent implements OnInit {
  @Output() clientListLoaded = new EventEmitter<ClientModel[]>();
  
  public clientSearchForm: FormGroup;
  public loading: boolean;

  private clientList: ClientModel[];

  constructor(
    private fb: FormBuilder,
    private giftCardActivationService: GiftCardActivationService
  ) {
    this.clientSearchForm = this.fb.group({
      name: [''],
      taxId: ['']
    }, { validators: [this.customValidator] }
    );
  }

  ngOnInit(): void {
  }

  customValidator(form: FormGroup) {
    if (form.value.name.length > 2 || form.value.taxId.length > 2) {
      return true;
    }
    else {
      return { name: false };
    }
 }

 public confirm() {
  this.loading = true;
  this.clientList = [];

  this.giftCardActivationService.getClient(this.clientSearchForm.value.name, this.clientSearchForm.value.taxId)
  .subscribe((res: GetClientResponse) => {
      if (res == null || res.clientList == undefined) {
        this.loading = false;
        return;
      }

      for (let x = 0; x < res.clientList.length; x++) {
        let clientItem = new ClientModel()
        clientItem.id = res.clientList[x].id;
        clientItem.taxId = res.clientList[x].taxId;
        clientItem.name = res.clientList[x].name;
        clientItem.address = res.clientList[x].address;
        clientItem.city = res.clientList[x].city;
        clientItem.email = res.clientList[x].email;
        clientItem.firstName = res.clientList[x].firstName;
        clientItem.lastName = res.clientList[x].lastName;
        clientItem.phone = res.clientList[x].phone;
        clientItem.zipCode = res.clientList[x].zipCode;
        this.clientList.push(clientItem);
      }

      this.loading = false;
    },
    (error) => {
      this.loading = false;
    },
    () => {
      this.clientListLoaded.emit(this.clientList);
      this.loading = false;
    });
 }
}