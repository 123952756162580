import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';
import { GiftCardActivationSingleComponent } from './gift-card-activation-single/gift-card-activation-single.component';
import { GiftCardActivationTableComponent } from './gift-card-activation-table/gift-card-activation-table.component';
import { NotificationService } from '@shared/notification/notification.service';
import { GiftCardActivationModel } from '../model/gift-card-activation.model';
import { GiftCardActivationService } from '../gift-card-activation.service';
import { forkJoin, Observable, of } from 'rxjs';
import { GiftCardActivatedModel } from '../model/gift-card-activated-model';
import { GiftCardActivationRangeComponent } from './gift-card-activation-range/gift-card-activation-range.component';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-gift-card-activation',
  templateUrl: './gift-card-activation.component.html',
  styleUrls: ['./gift-card-activation.component.scss']
})
export class GiftCardActivationComponent implements OnInit {
  @ViewChild('single', {static: false}) private giftCardActivationSingleComponent: GiftCardActivationSingleComponent;
  @ViewChild('table', {static: false}) private giftCardActivationTableComponent: GiftCardActivationTableComponent;
  @ViewChild('range', {static: false}) private giftCardActivationRangeComponent: GiftCardActivationRangeComponent;

  private authService: AuthRestService;
  public index = 0;
  cardsToActivate = [];

  constructor(
    private confirmationService: ConfirmationService, 
    private translocoService: TranslocoService,
    private giftCardActivationService: GiftCardActivationService,
    private notificationService: NotificationService,
    private authRestService: AuthRestService) { 
      this.authService = authRestService;
  }

  ngOnInit(): void {
  }

  public activatePopup($event): void {
    this.confirmationService.confirm({
      target: $event.event.target,
      message: this.buildPopupActivateMessage($event),
      accept: () => this.tryActivate(),
      reject: () => {}
    });
  }
  
  private buildPopupActivateMessage(data) {
    const cardsAmount = data.cardsAmount ?? 0;
    const value = data.valuesSum ?? 0;

    return `<div>${this.translocoService.translate('features.gift-card-activation.cards')}: ${cardsAmount}<br/>Value: ${value}<br/></div>
    <br/>
    <div>${this.translocoService.translate('shared.modal.operation-irreversible')}</div>`;
  }

  private tryActivate() {
    const cards: GiftCardActivationModel[] = []; 

    if (this.giftCardActivationSingleComponent && this.index === 0) {
      if (!this.giftCardActivationSingleComponent.getValidCards().filter((c) => c.number == '').length) {
        this.giftCardActivationSingleComponent.pushNewCard();
      }

      const singleCards = this.giftCardActivationSingleComponent.getValidCards()
        .filter(o => o.number && o.number.length && o.confirmed);
      cards.push(...singleCards);
    }

    if (this.giftCardActivationRangeComponent && this.index === 1) {
      if (!this.giftCardActivationRangeComponent.getValidCards().filter((c) => c.number == '').length) {
        
        const rangeCards = this.giftCardActivationRangeComponent.cards
        .filter(o => o.number && o.number.length && o.confirmed);
        cards.push(...rangeCards);
      }
    }

    if (this.giftCardActivationTableComponent && this.index === 2) {
      const tableCards = this.giftCardActivationTableComponent.getValidTransactions()
        .filter(o => o.cardNumber && o.cardNumber.length)
        .map(transaction => new GiftCardActivationModel(transaction.cardNumber, transaction.cardOperationID, transaction.transactionValue));
      cards.push(...tableCards);
    }

    if (!cards.length) {
      this.notificationService.addError(this.translocoService.translate('features.gift-card-activation.messages.no-cards'));
      return;
    }

    const list: Observable<GiftCardActivatedModel>[] = [];
    this.giftCardActivationService.activateCardList(cards, this.authService.userValue.userID).subscribe(
      (res) => {
        this.giftCardActivationService.setCardActivated(res.CardList.map(i => i.CardNumber));
        this.notificationService.addSuccess(this.translocoService.translate('common.messages.operation-completed'));
      }
    );

    if (this.giftCardActivationRangeComponent && this.index === 1) {
      this.giftCardActivationRangeComponent.clearForm();
    }
  }
}