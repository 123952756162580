<h2>{{ 'features.documents.documents' | transloco }}</h2>

<div class="col-12 d-flex align-items-center p-mb-3 p-0 mx-0 my-4">
    <p-dropdown class="mr-3" [(ngModel)]="filterModel.year" [options]="years" optionLabel="text" optionValue="value" (onChange)="filterTableByYear()"></p-dropdown>
    <p-dropdown class="mr-3" [(ngModel)]="filterModel.month" [options]="months" optionLabel="text" optionValue="value" (onChange)="filterTableByMonth()"></p-dropdown>
    <i *ngIf="loading" class="pi pi-spin pi-spinner spinner"></i>
</div>

<p-table #table [value]="documents" [paginator]="true" [rows]="25" [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'features.documents.showing' | transloco }} {first} {{ 'features.documents.to' | transloco }} {last} {{ 'features.documents.of' | transloco }} {totalRecords} {{ 'features.documents.entries' | transloco }}" [rowsPerPageOptions]="[25,50,100]"
    [globalFilterFields]="['name','dateString']" sortField="dateString" *ngIf="!loading">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 45%">
                <input pInputText type="text" (input)="table.filter($event.target.value, 'name', 'contains')"
                    class="p-column-filter" maxlength="256" />
            </th>
            <th style="width: 20%"></th>
            <th style="width: 10%"></th>
            <th style="width: 15%"></th>
        </tr>
        <tr>
            <th pSortableColumn="name">
                {{ 'features.documents.fields.report' | transloco }}
                <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="dateString">{{ 'features.documents.fields.date' | transloco }}
                <p-sortIcon field="dateString"></p-sortIcon>
            </th>
            <th pSortableColumn="extension">
                <p-sortIcon field="extension"></p-sortIcon>
            </th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-document>
        <tr>
            <td>{{ document.name }}</td>
            <td>{{ document.dateString }}</td>
            <td>
                <i class="{{ 'pi ' + document.ico }} pt-1"></i>
                {{ document.extension }}
            </td>
            <td class="text-right">
                <button pButton class="btn-small" (click)="onClickDownloadFile(document)" label="{{ 'features.documents.download' | transloco }}" [icon]="document.isDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'" iconPos="center">
                </button>
            </td>
        </tr>
    </ng-template>
</p-table>