import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoRootModule } from '@shared/transloco/transloco-root.module';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  declarations: [],
  imports: [CommonModule, TranslocoRootModule, MessagesModule, MessageModule],
  providers: [],
  exports: [MessageModule, MessagesModule],
})
export class NotificationModule {}
