import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardActivationHistoryGuard } from '@core/guards/card-activation-history.guard';
import { SharedModule } from '@shared/shared.module';
import { CardActivationHistoryTableComponent } from './component/card-activation-history-table/card-activation-history-table.component';

const routes: Routes = [
  { 
    path: 'card-activation-history', data: { id: 'card-activation-history' }, component: CardActivationHistoryTableComponent,
    canLoad: [CardActivationHistoryGuard],
    canActivate: [CardActivationHistoryGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  exports: [RouterModule, SharedModule]
})
export class CardActivationHistoryRountingModule { }
