import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";

export class ActivationHistoryCardModel {
    constructor(
        public cardID: string = '',
        public cardNumber: string = '',
        public value: number
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class ActivationHistoryCardModelAdapter implements Adapter<ActivationHistoryCardModel> {
  adapt(item: any): ActivationHistoryCardModel {
    return item ? new ActivationHistoryCardModel(
        item.CardID,
        item.CardNumber,
        item.Value ? item.Value.toFixed(2) : 0
      ) : null;
  }
}