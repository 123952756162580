<div class="col-sm-12">
    <h2>{{ 'features.gift-card-activation.gift-card-activation' | transloco }}</h2>
    <p-tabView [(activeIndex)]="index">
        <p-tabPanel [header]="'features.gift-card-activation.single.single' | transloco" [selected]="true">
            <app-gift-card-activation-single #single *ngIf="index === 0" (activatePopupEvent)="activatePopup($event)"></app-gift-card-activation-single>
        </p-tabPanel>
        <p-tabPanel [header]="'features.gift-card-activation.range.range' | transloco" >
            <app-gift-card-activation-range #range *ngIf="index === 1" (activatePopupEvent)="activatePopup($event)"></app-gift-card-activation-range>
        </p-tabPanel>
        <p-tabPanel [header]="'features.gift-card-activation.table.table' | transloco" >
            <app-gift-card-activation-table #table *ngIf="index === 2" (activatePopupEvent)="activatePopup($event)"></app-gift-card-activation-table>
        </p-tabPanel>
    </p-tabView>
</div>
<p-confirmDialog 
    [header]="'features.gift-card-activation.modal.gift-cards-activation' | transloco"
    [acceptLabel]="'features.gift-card-activation.activate' | transloco" 
    [rejectLabel]="'common.cancel' | transloco"
    acceptButtonStyleClass="p-button"
    rejectButtonStyleClass="p-button-secondary">
</p-confirmDialog>
