import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";

export class DocumentModel {
    constructor(
        public fullName: string = '',
        public name: string = '',
        public extension: string = '',
        public modifiedDate: Date
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class DocumentModelAdapter implements Adapter<DocumentModel> {
  adapt(item: any): DocumentModel {
    return item ? new DocumentModel(
      item.FullName, 
      item.Name,
      item.Extension,
      new Date(item.ModifiedDate)
      ) : null;
  }
}