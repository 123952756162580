import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ColorEnum } from '@shared/enums/color.enum';
import { SizeEnum } from '@shared/enums/size.enum';
import { IconRootNameEnum } from '@shared/icon/icon-root-name.enum';

@Component({
  selector: 'app-icon',
  exportAs: 'appIcon',
  template: `<i class="{{ iconRoot }} {{ iconRoot }}-{{ icon }}"></i>`,
})
export class IconComponent implements OnInit {
  private _icon: string;

  private _iconRoot: IconRootNameEnum = IconRootNameEnum.icon;

  private _color: ColorEnum;

  private _size: SizeEnum = SizeEnum.base;

  constructor() {}
  @Input()
  public get icon(): string {
    return this._icon;
  }

  public set icon(value: string) {
    this._icon = value;
  }

  @Input()
  public get iconRoot(): string {
    return this._iconRoot;
  }

  public set iconRoot(value: string) {
    this._iconRoot = IconRootNameEnum[value];
  }

  @Input()
  public get color(): ColorEnum {
    return this._color;
  }

  public set color(value: ColorEnum) {
    this._color = ColorEnum[value];
  }

  @Input()
  public get size(): SizeEnum {
    return this._size;
  }

  public set size(value: SizeEnum) {
    this._size = SizeEnum[value];
  }

  @HostBinding('class')
  public get cssClasses(): string {
    const cssClasses = [];

    if (this._color) {
      cssClasses.push(this._getColorCssClass());
    }
    cssClasses.push(this._getSizeCssClass());

    return cssClasses.join(' ');
  }

  public ngOnInit(): void {}

  private _getColorCssClass(): string {
    return `text-${this._color}`;
  }

  private _getSizeCssClass(): string {
    return `font-size-${this._size}`;
  }
}
