import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";
import { ActivationHistoryCardModel, ActivationHistoryCardModelAdapter } from "./activation-history-card-model";

export class GetActivationHistoryModel {
    constructor(
        public cardActivationHistoryID: string = '',
        public cardActivationDate: Date,
        public quantity: number,
        public value: number,
        public invoiceID: string = '',
        public invoiceName: string = '',
        public batchID: string = '',
        public batch: string = '',
        public cardList: ActivationHistoryCardModel[]
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class GetActivationHistoryModelAdapter implements Adapter<GetActivationHistoryModel> {
  adapt(item: any): GetActivationHistoryModel {
    return item ? new GetActivationHistoryModel(
        item.CardActivationHistoryID, 
        new Date(item.CardActivationDate),
        item.Quantity, 
        item.Value ? item.Value.toFixed(2) : 0,
        item.InvoiceID,
        item.InvoiceName,
        item.BatchID,
        item.Batch,
        item.CardList.map(i => new ActivationHistoryCardModelAdapter().adapt(i))
        ) : null;
  }
}