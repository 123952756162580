import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";

export class GiftCardActivationTableElemModel {
  constructor(
      public cardOperationID: string = '',
      public transactionNumber: string = '',
      public transactionValue: number,
      public transactionDate: Date,
      public cardNumber: string = '',
      public shippingAddress: string = '',
      public batchID: string = '',
      public batch: string = '',
      public graphicUrl: string = '',
      public canActivate: boolean = null,
      public executionStatus: number = 0
  ) {}
}

@Injectable({
    providedIn: "root",
})
export class GiftCardActivationTableElemModelAdapter implements Adapter<GiftCardActivationTableElemModel> {
  adapt(item: any): GiftCardActivationTableElemModel {
    return new GiftCardActivationTableElemModel(
      item.CardOperationID, 
      item.TransactionNumber, 
      item.TransactionValue.toFixed(2), 
      new Date(item.TransactionDate),
      item.CardNumber, 
      item.ShippingAddress,
      item.BatchID,
      item.Batch,
      item.GraphicUrl);
  }
}