import { Component, Input, OnInit } from '@angular/core';
import { CurrentUserViewModel } from '@core/models/current-user.view-model';
import { NavItemModel } from '@core/models/nav-item.model';
import { SidebarNavHelper } from '@shared/sidebar/component/sidebar-nav/service/sidebar-nav.helper';

@Component({
  selector: 'app-sidebar-nav-items',
  template: `
    <ng-container *ngFor="let item of items">
      <ng-container [ngSwitch]="helper.itemType(item)" *ngIf="isVisible(item)">
        <app-sidebar-nav-dropdown
          *ngSwitchCase="'dropdown'"
          [user]="user"
          [item]="item"
          [class.open]="helper.isActive(item)"
          [ngClass]="helper.getClass(item)"
          appNavDropdown
          routerLinkActive="open"
        >
        </app-sidebar-nav-dropdown>
        <app-sidebar-nav-divider *ngSwitchCase="'divider'" [item]="item" [ngClass]="helper.getClass(item)">
        </app-sidebar-nav-divider>
        <app-sidebar-nav-title *ngSwitchCase="'title'" [item]="item" [ngClass]="helper.getClass(item)">
        </app-sidebar-nav-title>
        <app-sidebar-nav-label *ngSwitchCase="'label'" [item]="item" class="nav-item" [ngClass]="helper.getClass(item)">
        </app-sidebar-nav-label>
        <ng-container *ngSwitchCase="'empty'"> </ng-container>
        <app-sidebar-nav-link *ngSwitchDefault [item]="item" class="nav-item" [ngClass]="helper.getClass(item)">
        </app-sidebar-nav-link>
      </ng-container>
    </ng-container>
  `,
})
export class SidebarNavItemsComponent implements OnInit {
  @Input() public items: NavItemModel[];
  @Input() public user?: CurrentUserViewModel;

  constructor(public helper: SidebarNavHelper) {}

  public ngOnInit(): void {}

  public isVisible(item: NavItemModel): boolean {
    if (item.hasOwnProperty('roles')) {
      const userRoles: string[] = this.user.roles;
      const itemRoles: string[] = item.roles;
      const intersection = userRoles.filter((value: string) => itemRoles.includes(value));
      return Boolean(intersection.length);
    }
    return true;
  }
}
