import { Injectable } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { NavItemTypeEnum } from '@core/models/nav-item-type.enum';
import { NavItemModel } from '@core/models/nav-item.model';

@Injectable()
export class SidebarNavHelper {
  constructor(private readonly router: Router) {}

  public itemType(item: NavItemModel): NavItemTypeEnum {
    if (item.divider) {
      return NavItemTypeEnum.divider;
    } else if (item.title) {
      return NavItemTypeEnum.title;
    } else if (item.children) {
      return NavItemTypeEnum.dropdown;
    } else if (item.label) {
      return NavItemTypeEnum.label;
    } else if (!Object.keys(item).length) {
      return NavItemTypeEnum.empty;
    } else {
      return NavItemTypeEnum.link;
    }
  }

  public getClass(item: NavItemModel): string {
    const itemType = this.itemType(item);
    let itemClass;
    itemClass =
      itemType in ['divider', 'title']
        ? `nav-${itemType}`
        : itemType === NavItemTypeEnum.dropdown
        ? 'nav-item nav-dropdown'
        : 'nav-item';
    return item.class ? `${itemClass} ${item.class}` : itemClass;
  }

  public isActive(item: NavItemModel): boolean {
    if (item.hasOwnProperty('children')) {
      return item.children.some((e: NavItemModel) => {
        return this.router.isActive(this.getPath(e), false);
      });
    }
  }

  public redirectTo(item: NavItemModel): string {
    if (item.hasOwnProperty('redirectTo')) {
      const config = this.router.config;
      const route = this.findById(config, item.redirectTo) as Route;
      return `/${route.path}`;
    }
  }

  public hasBadge = (item) => Boolean(item.badge);
  public hasIcon = (item) => Boolean(item.icon);

  public getIconClass(item: NavItemModel): { 'nav-icon': boolean } {
    const classes = {
      'nav-icon': true,
    };
    const icon = item.icon;
    classes[icon] = !!item.icon;
    return classes;
  }

  public getBadgeClass(item: NavItemModel): { badge: boolean } {
    const classes = {
      badge: true,
    };
    const variant = `badge-${item.badge.variant}`;
    classes[variant] = !!item.badge.variant;
    return classes;
  }

  public getPath(item: NavItemModel): string {
    const config = this.router.config;
    const result = this.findById(config, item.id) as Route;
    return result && result.hasOwnProperty('path') ? `/app/${result.path}` : '/404';
  }

  public findById(config: Routes, itemId: string): boolean | Route {
    if (!Array.isArray(config)) {
      return false;
    }
    for (const e of config) {
      if (e.hasOwnProperty('data') && e.data.hasOwnProperty('id') && e.data.id === itemId) {
        return e;
      } else if (e.hasOwnProperty('children')) {
        const child = this.findById(e.children, itemId);
        if (child) {
          return child;
        }
      }
    }
  }
}
