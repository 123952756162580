<h2>{{ 'features.card-activation-history.card-activation-history' | transloco }}</h2>

<div class="col-12 d-flex justify-content-between align-items-center p-mb-3 p-0 mx-0 my-4">
    <div class="d-flex">
        <p-dropdown class="mr-3" [(ngModel)]="filterModel.year" [options]="years" optionLabel="text" optionValue="value" (onChange)="filterTableByYear()"></p-dropdown>
        <p-dropdown class="mr-3" [(ngModel)]="filterModel.month" [options]="months" optionLabel="text" optionValue="value" (onChange)="filterTableByMonth()"></p-dropdown>
        <div>
            <i *ngIf="loading" class="pi pi-spin pi-spinner spinner"></i>
        </div>
    </div>
    <p-button class="btn float-right"
        label="{{ 'features.card-activation-history.create-invoice' | transloco }} {{getSelectedRowsLabel()}}"
        (onClick)="onClickCreateInvoice()"
        [disabled]="selectedActivationHistoryList.length == 0">
        <i class="pi pi-spin pi-spinner spinner-sm" *ngIf="creatingInvoice"></i>
    </p-button>
</div>

<p-table #table [value]="activationHistoryList" [paginator]="true" [rows]="25" [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'features.documents.showing' | transloco }} {first} {{ 'features.documents.to' | transloco }} {last} {{ 'features.documents.of' | transloco }} {totalRecords} {{ 'features.documents.entries' | transloco }}" [rowsPerPageOptions]="[25,50,100]" [globalFilterFields]="['batch','dateString']" sortField="historyDate" *ngIf="!loading" dataKey="cardActivationHistoryID" [(selection)]="selectedActivationHistoryList">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 5%"></th>
            <th style="width: 25%">
                <input pInputText type="text" (input)="table.filter($event.target.value, 'batch', 'contains')" class="p-column-filter" maxlength="256" />
            </th>
            <th style="width: 15%"></th>
            <th style="width: 20%"></th>
            <th style="width: 20%"></th>
            <th style="width: 5%"></th>
            <th style="width: 15%"></th>
        </tr>
        <tr>
            <th style="width: 3rem"></th>
            <th pSortableColumn="batch">{{ 'features.card-activation-history.fields.batch' | transloco }}
                <p-sortIcon field="batch"></p-sortIcon>
            </th>
            <th pSortableColumn="quantity">{{ 'features.card-activation-history.fields.quantity' | transloco }}
                <p-sortIcon field="quantity"></p-sortIcon>
            </th>
            <th pSortableColumn="value">{{ 'features.card-activation-history.fields.totalValue' | transloco }}
                <p-sortIcon field="value"></p-sortIcon>
            </th>
            <th pSortableColumn="dateString">{{ 'features.card-activation-history.fields.activation-date' | transloco }}
                <p-sortIcon field="dateString"></p-sortIcon>
            </th>
            <th style="width: 4rem"></th>
            <th style="width: 10%"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-activationHistoryItem let-expanded="expanded">
        <tr [pSelectableRow]="activationHistoryItem">
            <td>
                <button type="button" pButton pRipple [pRowToggler]="activationHistoryItem" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{ activationHistoryItem.batch }}</td>
            <td>{{ activationHistoryItem.quantity }}</td>
            <td>{{ activationHistoryItem.value }}</td>
            <td>{{ activationHistoryItem.dateString }}</td>
            <td>
                <p-tableCheckbox [value]="activationHistoryItem" hidden="{{activationHistoryItem.invoiceID}}"></p-tableCheckbox>
            </td>
            <td class="text-right">
                <button pButton class="btn-small" (click)="onClickDownloadInvoice(activationHistoryItem)" [icon]="activationHistoryItem.isDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'" *ngIf="activationHistoryItem.invoiceID" label="{{ 'common.download' | transloco }}" iconPos="center"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-activationHistoryItem>
        <tr>
            <td colspan="4">
                <div class="p-p-2">
                    <p-table [value]="activationHistoryItem.cardList" dataKey="cardID">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="cardNumber">{{ 'features.card-activation-history.fields.card-number' | transloco }}<p-sortIcon field="cardNumber"></p-sortIcon></th>
                                <th pSortableColumn="value">{{ 'features.card-activation-history.fields.value' | transloco }}<p-sortIcon field="value"></p-sortIcon></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-card>
                            <tr>
                                <td>{{card.cardNumber}}</td>
                                <td>{{card.value}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">There are no order for this product yet.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
