import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AppLayoutComponent } from '@core/layout/app/app-layout.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { GiftCardActivationModule } from '@features/gift-card-activation/gift-card-activation.module';
import { DocumentsModule } from '@features/documents/documents.module';
import { SettingsModule } from '@features/settings/settings.module';
import { CardActivationHistoryModule } from '@features/card-activation-history/card-activation-history.module';
import { ClientsModule } from '@features/clients/clients.module';

const routes: Routes = [
  {
    path: 'app',
    component: AppComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AppLayoutComponent,
        loadChildren: () =>
          import(
            '@features/gift-card-activation/gift-card-activation.module'
          ).then(
            (m: { GiftCardActivationModule: GiftCardActivationModule }) =>
              m.GiftCardActivationModule
          ),
      },
      {
        path: '',
        component: AppLayoutComponent,
        loadChildren: () =>
          import('@features/documents/documents.module').then(
            (m: { DocumentsModule: DocumentsModule }) => m.DocumentsModule
          ),
      },
      {
        path: '',
        component: AppLayoutComponent,
        loadChildren: () =>
          import(
            '@features/card-activation-history/card-activation-history.module'
          ).then(
            (m: { CardActivationHistoryModule: CardActivationHistoryModule }) =>
              m.CardActivationHistoryModule
          ),
      },
      {
        path: '',
        component: AppLayoutComponent,
        loadChildren: () =>
          import('@features/settings/settings.module').then(
            (m: { SettingsModule: SettingsModule }) => m.SettingsModule
          ),
      },
      {
        path: '',
        component: AppLayoutComponent,
        loadChildren: () =>
          import('@features/clients/clients.module').then(
            (m: { ClientsModule: ClientsModule }) => m.ClientsModule
          ),
      },
    ],
  },
  { path: '**', redirectTo: '/auth/login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
