import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';

@Component({ selector: "app-auth-layout", templateUrl: 'auth-layout.component.html' })
export class AuthLayoutComponent {
    constructor(
        private router: Router,
        private authRestService: AuthRestService
    ) {
      this.redirectIfIsLoggedIn();
    }
    
    private redirectIfIsLoggedIn(): void {
      const loggedIn = this.authRestService.userValue;
      if(!loggedIn)
        return;

      this.router.navigate(['/home/']);
    }
}