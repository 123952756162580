<div class="col">
<ng-container  *ngFor="let card of cards; last as last">
  <app-gift-card-activation-elem [isLast]="last" [model]="card" (newCardEvent)="pushNewCard()" (editCardEvent)="editCard($event)"></app-gift-card-activation-elem>
</ng-container>
  <div class="row">
    <div class="col-lg-6 col-sm-12"></div>
    <div class="col-lg-6 col-sm-12">
      <app-form-group>
        <button pButton class="btn float-right" (click)="emitActivatePopupEvent($event)" label="{{ 'features.gift-card-activation.activate' | transloco }}"></button>
      </app-form-group>
    </div>
  </div>
</div>