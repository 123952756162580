import { Injectable } from "@angular/core";
import { Adapter } from "@core/helpers/adapterInterface";
import { GiftCardModel, GiftCardModelAdapter } from "./gift-card-model";

export class GiftCardResponse {
    constructor(
        public executionStatus: number,
        public cards: GiftCardModel[]
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class GiftCardResponseAdapter implements Adapter<GiftCardResponse> {
  adapt(item: any): GiftCardResponse {
    return item ? new GiftCardResponse(
      item.ExecutionStatus,
      item.PCCardList?.map(i => new GiftCardModelAdapter().adapt(i))
      ) : null;
  }
}