import { Component, Input, OnInit } from '@angular/core';
import { NavItemModel } from '@core/models/nav-item.model';
import { SidebarNavHelper } from '@shared/sidebar/component/sidebar-nav/service/sidebar-nav.helper';

@Component({
  selector: 'app-sidebar-nav-label',
  templateUrl: './label.component.html',
})
export class SidebarNavLabelComponent implements OnInit {
  @Input() public item: NavItemModel;

  constructor(public helper: SidebarNavHelper) {}

  public ngOnInit(): void {}

  public getItemClass(): { 'nav-label': boolean; 'active': boolean } {
    const labelClass = {
      'nav-label': true,
      'active': true,
    };
    const itemClass = this.item.class;
    labelClass[itemClass] = !!itemClass;
    return labelClass;
  }

  public getLabelIconClass(): {
    'nav-icon': boolean;
  } {
    const classes = this.helper.getIconClass(this.item);
    const variant = `text-${this.item.label.variant}`;
    classes[variant] = !!variant;
    const labelClass = this.item.label.class;
    classes[labelClass] = !!labelClass;
    return classes;
  }
}
