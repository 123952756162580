import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { DateTime } from 'luxon';
import { FilterService, PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject, Observable, zip } from 'rxjs';

type DatePatterns = {
  short: { [index: string]: string };
  long: { [index: string]: string };
  medium: { [index: string]: string };
};

type RowsPerPageOptionsType = {
  default: number[];
  changeLogs: number[];
};

@Injectable()
export class ApplicationConfig {
  public readonly stateKey = 'language';
  
  public get monthsMap(): Map<string, any> {
    return  new Map([
      [ '1', this.translateService.translate('calendar.months.january') ],
      [ '2', this.translateService.translate('calendar.months.february') ],
      [ '3', this.translateService.translate('calendar.months.march') ],
      [ '4', this.translateService.translate('calendar.months.april') ],
      [ '5', this.translateService.translate('calendar.months.may') ],
      [ '6', this.translateService.translate('calendar.months.june') ],
      [ '7', this.translateService.translate('calendar.months.july') ],
      [ '8', this.translateService.translate('calendar.months.august') ],
      [ '9', this.translateService.translate('calendar.months.september') ],
      [ '10', this.translateService.translate('calendar.months.october') ],
      [ '11', this.translateService.translate('calendar.months.november') ],
      [ '12', this.translateService.translate('calendar.months.december') ]
  ]);
  };

  public datePatterns: DatePatterns = {
    short: {
      en: 'mm/dd/yy',
      pl: 'dd/mm/yy',
      de: 'dd/mm/yy',
    },
    medium: {
      en: 'MM/dd/yyyy',
      pl: 'dd/MM/yyyy',
      de: 'dd/MM/yyyy',
    },
    long: {
      en: 'MM/dd/yyyy, hh:mm a',
      pl: 'dd/MM/yyyy, hh:mm',
      de: 'dd/MM/yyyy, hh:mm',
    },
  };

  public rowsPerPageOptions: RowsPerPageOptionsType = {
    default: [25, 50, 100],
    changeLogs: [50, 100, 250],
  };
  private readonly state = new BehaviorSubject<string>(environment.defaultLocale);

  public constructor(
    private readonly translateService: TranslocoService,
    private readonly primeNGConfig: PrimeNGConfig,
    private readonly filterService: FilterService,
  ) {}

  public set locale(locale: string) {
    this.state.next(locale);
    localStorage.setItem(this.stateKey, locale);
  }

  public get locale(): string {
    return this.state.getValue();
  }

  private get days(): Observable<string[]> {
    return zip(
      this.translateService.selectTranslate('shared.calendar.day.sunday'),
      this.translateService.selectTranslate('shared.calendar.day.monday'),
      this.translateService.selectTranslate('shared.calendar.day.tuesday'),
      this.translateService.selectTranslate('shared.calendar.day.wednesday'),
      this.translateService.selectTranslate('shared.calendar.day.thursday'),
      this.translateService.selectTranslate('shared.calendar.day.friday'),
      this.translateService.selectTranslate('shared.calendar.day.saturday'),
    );
  }

  private get shortDays(): Observable<string[]> {
    return zip(
      this.translateService.selectTranslate('shared.calendar.day.short.sunday'),
      this.translateService.selectTranslate('shared.calendar.day.short.monday'),
      this.translateService.selectTranslate('shared.calendar.day.short.tuesday'),
      this.translateService.selectTranslate('shared.calendar.day.short.wednesday'),
      this.translateService.selectTranslate('shared.calendar.day.short.thursday'),
      this.translateService.selectTranslate('shared.calendar.day.short.friday'),
      this.translateService.selectTranslate('shared.calendar.day.short.saturday'),
    );
  }

  private get minDays(): Observable<string[]> {
    return zip(
      this.translateService.selectTranslate('shared.calendar.day.min.sunday'),
      this.translateService.selectTranslate('shared.calendar.day.min.monday'),
      this.translateService.selectTranslate('shared.calendar.day.min.tuesday'),
      this.translateService.selectTranslate('shared.calendar.day.min.wednesday'),
      this.translateService.selectTranslate('shared.calendar.day.min.thursday'),
      this.translateService.selectTranslate('shared.calendar.day.min.friday'),
      this.translateService.selectTranslate('shared.calendar.day.min.saturday'),
    );
  }

  private get months(): Observable<string[]> {
    return zip(
      this.translateService.selectTranslate('shared.calendar.month.january'),
      this.translateService.selectTranslate('shared.calendar.month.february'),
      this.translateService.selectTranslate('shared.calendar.month.march'),
      this.translateService.selectTranslate('shared.calendar.month.april'),
      this.translateService.selectTranslate('shared.calendar.month.may'),
      this.translateService.selectTranslate('shared.calendar.month.june'),
      this.translateService.selectTranslate('shared.calendar.month.july'),
      this.translateService.selectTranslate('shared.calendar.month.august'),
      this.translateService.selectTranslate('shared.calendar.month.september'),
      this.translateService.selectTranslate('shared.calendar.month.october'),
      this.translateService.selectTranslate('shared.calendar.month.november'),
      this.translateService.selectTranslate('shared.calendar.month.december'),
    );
  }

  private get shortMonths(): Observable<string[]> {
    return zip(
      this.translateService.selectTranslate('shared.calendar.month.short.january'),
      this.translateService.selectTranslate('shared.calendar.month.short.february'),
      this.translateService.selectTranslate('shared.calendar.month.short.march'),
      this.translateService.selectTranslate('shared.calendar.month.short.april'),
      this.translateService.selectTranslate('shared.calendar.month.short.may'),
      this.translateService.selectTranslate('shared.calendar.month.short.june'),
      this.translateService.selectTranslate('shared.calendar.month.short.july'),
      this.translateService.selectTranslate('shared.calendar.month.short.august'),
      this.translateService.selectTranslate('shared.calendar.month.short.september'),
      this.translateService.selectTranslate('shared.calendar.month.short.october'),
      this.translateService.selectTranslate('shared.calendar.month.short.november'),
      this.translateService.selectTranslate('shared.calendar.month.short.december'),
    );
  }

  private get today(): Observable<string> {
    return this.translateService.selectTranslate('shared.calendar.today');
  }

  private get clear(): Observable<string> {
    return this.translateService.selectTranslate('shared.calendar.clear');
  }

  public init(): void {
    this.translateService.setDefaultLang(environment.defaultLocale);
    this.locale = localStorage.getItem(this.stateKey) || environment.defaultLocale;
    this.translateService.setActiveLang(this.locale);
    //this.setPrimeNGConfig();
    this.registerDateFilter();
  }

  private registerDateFilter(): void {
    this.filterService.register('date', this.filterBySingleDate);
    this.filterService.register('dateRange', this.filterByRangeDate);
  }

  private readonly filterBySingleDate = (value: DateTime, filter: DateTime | null) => {
    if (filter === undefined || filter === null) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }
    const hasSameYear = value.hasSame(filter, 'year');
    const hasSameMonth = value.hasSame(filter, 'month');
    const hasSameDay = value.hasSame(filter, 'day');
    return hasSameYear && hasSameMonth && hasSameDay;
  };

  private readonly filterByRangeDate = (value: DateTime, filter: DateTime[] | null) => {
    if (filter === undefined || filter === null || !Array.isArray(filter) || filter.length === 0) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }
    const isoValueDate = value.toISODate();
    const isoFilter1Date = filter[0].toISODate();
    const isoFilter2Date = filter[1].toISODate();
    return isoValueDate >= isoFilter1Date && isoValueDate <= isoFilter2Date;
  };

  private setPrimeNGConfig(): void {
    zip(this.months, this.shortMonths, this.minDays, this.days, this.shortDays, this.today, this.clear).subscribe(
      ([monthNames, monthNamesShort, dayNamesMin, dayNames, dayNamesShort, today, clear]: [
        string[],
        string[],
        string[],
        string[],
        string[],
        string,
        string,
      ]) => {
        this.primeNGConfig.ripple = true;
        this.primeNGConfig.setTranslation({
          dayNames,
          dayNamesMin,
          dayNamesShort,
          monthNamesShort,
          today,
          monthNames,
          clear,
        });
      },
    );
  }
}
