import { Component, OnInit } from '@angular/core';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { NavItemModel } from '@core/models/nav-item.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {
  menuItems: NavItemModel[] = [];
  display: boolean = true;
  router: Router;

  constructor(private authRestService: AuthRestService,
              private arouter: Router) {
    this.router = arouter;
  }

  ngOnInit() {
    this.fillMenuItems();
  }

    public logout(): void {
      this.authRestService.logout();
    }

    public settings(): void {
      this.router.navigateByUrl('/app/settings');
    }

    private fillMenuItems(): void {
        this.menuItems = [
          {
            id: 'gift-card-activation',
            name:"Gift cards activation",
            icon:'pi pi-fw pi-credit-card',
          },
          {
            id: 'card-activation-history',
            name:'Activation history',
            icon:'pi pi-fw pi-book',
          },
          {
            id: 'clients',
            name:'Clients',
            icon:'pi pi-fw pi-users',
          },
          {
            id: 'documents',
            name:'Documents',
            icon:'pi pi-fw pi-file-o',
          }
      ];
    }
    public toggleSidebarMenu() {
      this.display = !this.display;
    }
  }
